export const accessOptions = [
    {
        label: "Разрешено",
        value: 1
    },
    {
        label: 'Запрещено',
        value: 2
    }
]


export const labelFromAccessOption = {
    1: "Разрешено",
    2: "Запрещено"
}


export const labelFromField: Record<string, string> = {
    adminRule: "Администраторы",
    eventRule: "События",
    messageRule: "Сообщения",
    platformRule: "Платформы",
    templateRule: "Шаблоны",
    userRule: "Пользователи",
    variableRule: "Переменные",
}
