import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchDeleteGroups } from 'store/action/groups';

import { Button, Subtitle, Title } from 'components';

import './DeleteGroup.scss';

const CnDeleteGroup = cn('deleteAdmin');

export const DeleteGroup: React.FC = () => {
    const location = useLocation();
    const state = location.state as { name: string; id: string };

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const deleteClickHandler = () => {
        navigate(location.pathname);

        dispatch(fetchDeleteGroups({ templateGroupId: state.id }));
    };

    const cancelClickHandler = () => {
        navigate(location.pathname, { state });
    };

    return (
        <div className={CnDeleteGroup()}>
            <div className={CnDeleteGroup('header')}>
                <Title size="xs">Удалить группу</Title>
                <Subtitle size="m">{state?.name}</Subtitle>
            </div>

            <div className={CnDeleteGroup('actions')}>
                <Button onClick={deleteClickHandler} view="primary" size="m">
                    Удалить
                </Button>

                <Button onClick={cancelClickHandler} view="secondary" size="m">
                    Отмена
                </Button>
            </div>
        </div>
    );
};
