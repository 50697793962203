import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import moment from 'moment';
import { fetchBlockReceivers } from 'store/action/receivers';
import { Receiver } from 'store/reducer/receivers';
import { UserBannStatus } from 'types/rules';

import { TableItem } from 'components';

import './ReceiversTableItem.scss';

const CnReceiversTableItem = cn('receiversTableItem');

export const ReceiversTableItem: React.FC<Receiver> = (props) => {
    const { name, lastTimeMessage, externalUserId, id, userStatus, countUnsubscribed } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const rowClickHandler = () => {
        navigate('/receivers/messages', { state: props });
    };

    const usubscribeActionClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        navigate('?modal=unsubscribe', {
            state: {
                userId: props.id,
            },
        });
    };

    const blockActionClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        dispatch(
            fetchBlockReceivers({
                userId: id,
                userStatus: userStatus === UserBannStatus.BANNED ? UserBannStatus.NOT_BANNED : UserBannStatus.BANNED,
            }),
        );
    };

    return (
        <tr onClick={rowClickHandler} className="receiversTableItem">
            <TableItem>{externalUserId}</TableItem>
            <TableItem>{name}</TableItem>
            <TableItem>{moment(lastTimeMessage).format('MMM D, YYYY HH:mm')}</TableItem>
            <TableItem>{countUnsubscribed}</TableItem>
            <TableItem>
                <div className={CnReceiversTableItem('actions')}>
                    <div className={CnReceiversTableItem('action')} onClick={usubscribeActionClickHandler}>
                        <svg
                            className={CnReceiversTableItem('icon')}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18.3335 3.66666H3.66683C2.6585 3.66666 1.84266 4.49166 1.84266 5.5L1.8335 16.5C1.8335 17.5083 2.6585 18.3333 3.66683 18.3333H18.3335C19.3418 18.3333 20.1668 17.5083 20.1668 16.5V5.5C20.1668 4.49166 19.3418 3.66666 18.3335 3.66666ZM18.3335 16.5H3.66683V7.33333L11.0002 11.9167L18.3335 7.33333V16.5ZM11.0002 10.0833L3.66683 5.5H18.3335L11.0002 10.0833Z"
                                fill="#201D1D"
                            />
                        </svg>
                        Отписки
                    </div>

                    <div className={CnReceiversTableItem('action')} onClick={blockActionClickHandler}>
                        <svg
                            className={CnReceiversTableItem('icon')}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.0002 1.83333C5.94016 1.83333 1.8335 5.94 1.8335 11C1.8335 16.06 5.94016 20.1667 11.0002 20.1667C16.0602 20.1667 20.1668 16.06 20.1668 11C20.1668 5.94 16.0602 1.83333 11.0002 1.83333ZM3.66683 11C3.66683 6.94833 6.9485 3.66666 11.0002 3.66666C12.696 3.66666 14.2543 4.24416 15.4918 5.21583L5.216 15.4917C4.24433 14.2542 3.66683 12.6958 3.66683 11ZM11.0002 18.3333C9.30433 18.3333 7.746 17.7558 6.5085 16.7842L16.7843 6.50833C17.756 7.74583 18.3335 9.30416 18.3335 11C18.3335 15.0517 15.0518 18.3333 11.0002 18.3333Z"
                                fill="#201D1D"
                            />
                        </svg>
                        {userStatus === UserBannStatus.BANNED ? 'Разблокировать' : 'Заблокировать'}
                    </div>
                </div>
            </TableItem>
        </tr>
    );
};
