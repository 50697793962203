import { host } from 'constants/api';
import { itemsPerPageCount } from 'constants/pagination';

import axios, { AxiosResponse } from 'axios';
import { Receiver } from 'store/reducer/receivers';
import { Response } from 'types/api';
import { UserBannStatus } from 'types/rules';

interface ReceiversResponse extends Response {
    users: Receiver[];
    countItems: number;
}

export const fetchReceiversRequest = async (payload: { offset: number, search: string, sort: 'asc' | 'desc' | null }): Promise<ReceiversResponse> => {
    let search = "";

    if (payload.search) {
        search += `&search=${payload.search}`
    }

    if (payload.sort) {
        search += `&sortBy=externalUserId.${payload.sort}`
    }

    return await axios.get<never, AxiosResponse<ReceiversResponse>>(`${host}/admin/user/all?offset=${payload.offset}&limit=${itemsPerPageCount}${search}`).then((res) => res.data);
};

interface ReceiversBlockResponse extends Response {
    users: Receiver;
}

export const fetchReceiversBlockRequest = async (payload: { userId: string, userStatus: UserBannStatus }): Promise<ReceiversBlockResponse> => {
    return await axios.put<never, AxiosResponse<ReceiversBlockResponse>>(`${host}/admin/user`, payload).then((res) => res.data);
};
