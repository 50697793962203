/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { itemsPerPageCount } from "constants/pagination";

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
    fetchAddEventRequest,
    fetchChangeEventRequest,
    fetchDeleteEventRequest,
    fetchEventsRequest
} from "api/events";


export const fetchEvents = createAsyncThunk("@events/fetch", async (payload: { offset: number, limit?: number, search?: string }) => {
    return await fetchEventsRequest({ limit: 150, ...payload });
})

export const fetchAddEvent = createAsyncThunk("@event/add", async (data: any) => {
    await fetchAddEventRequest(data);

    return await fetchEventsRequest({ offset: 0, limit: itemsPerPageCount });
})

export const fetchChangeEvent = createAsyncThunk("@event/change", async (data: any) => {
    await fetchChangeEventRequest(data);

    return await fetchEventsRequest({ offset: 0, limit: itemsPerPageCount });
})

export const fetchDeleteEvent = createAsyncThunk("@events/delete", async (payload: { eventId: string }) => {
    await fetchDeleteEventRequest(payload);

    return await fetchEventsRequest({ offset: 0, limit: itemsPerPageCount });
})

export const setEventsCurrentPage = createAction<number>("@events/setCurrentPage");

export const setEventsSearch = createAction<string>("@events/setSearch");

