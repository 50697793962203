import { host } from "constants/api"
import { itemsPerPageCount } from "constants/pagination";

import axios, { AxiosResponse } from "axios"
import { Log } from "store/reducer/logs"
import { Response, Sort } from "types/api";

interface FetchLogsResponse extends Response {
    adminLogs: Log[];
    countItems: number;
}

export const fetchLogsRequest = async (payload: { id: string, offset: number, from: string, to: string, sort: Sort | null }): Promise<FetchLogsResponse> => {
    return await axios.get<never, AxiosResponse<FetchLogsResponse>>(`${host}/admin/admins/logs?adminId=${payload.id}&dateFrom=${payload.from}&dateTo=${payload.to}&limit=${itemsPerPageCount}&offset=${payload.offset}`, {
        params: {
            sortBy: payload.sort ? `created_at.${payload.sort}` : null
        }
    }).then(res => res.data)
}