import React from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Plus } from 'assets';

import { AdminsTable, Button, Title } from 'components';

import './Admins.scss';

const CnAdmins = cn('admins');

export const Admins: React.FC = () => {
    const navigate = useNavigate();

    const addAdminClickHandler = () => {
        navigate('?modal=add-admin');
    };

    return (
        <div className={CnAdmins()}>
            <div className={CnAdmins('header')}>
                <Title size="m">Администраторы</Title>

                <Button onClick={addAdminClickHandler} view="secondary" size="m">
                    <Plus /> Добавить
                </Button>
            </div>

            <div className={CnAdmins('content')}>
                <AdminsTable />
            </div>
        </div>
    );
};
