import React from 'react';
import { cn } from '@bem-react/classname';

import './Title.scss';

const CnTitle = cn('title');

interface ITitleProps {
    size?: 'xxs' | 'xs' | 's' | 'm' | 'l';
}

export const Title: React.FC<ITitleProps> = ({ children, size = 'l' }) => {
    return <div className={CnTitle({ size })}>{children}</div>;
};
