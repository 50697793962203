import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { cn } from '@bem-react/classname';
import { Trash } from 'assets';
import { fetchAddUnsubscribe, fetchDeleteUnsubscribe, fetchUnsubscribes } from 'store/action/unsubscribes';
import { useAppSelector } from 'store/store';

import { Button, Input, Subtitle, Title } from 'components';

import './Unsubscribe.scss';

const CnUnsubscribe = cn('unsubscribe');

export const Unsubscribe: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const state = location.state as { userId: string };

    const { data } = useAppSelector((store) => store.unsubscribes);
    const [value, setValue] = useState('');

    useEffect(() => {
        dispatch(fetchUnsubscribes(state.userId));
    }, [dispatch, state]);

    const submitClickHandler = () => {
        dispatch(
            fetchAddUnsubscribe({
                userId: state.userId,
                unsubscribe: value,
            }),
        );
    };

    const deleteUnsubscribeClickHandler = (unsubscribeId: string) => {
        dispatch(
            fetchDeleteUnsubscribe({
                userId: state.userId,
                unsubscribeId: unsubscribeId,
            }),
        );
    };

    const valueChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }, []);

    return (
        <div className={CnUnsubscribe()}>
            <div className={CnUnsubscribe('header')}>
                <Title size="xs">Отписки</Title>

                <Subtitle size="m">
                    Уведомления, которые пользователь <b>не</b> получает
                </Subtitle>
            </div>

            <div className={CnUnsubscribe('form')}>
                <Input placeholder="Название отписки" value={value} onChange={valueChangeCallback} />
            </div>

            <div className={CnUnsubscribe('actions')}>
                <Button onClick={submitClickHandler} view="primary" size="m">
                    Добавить
                </Button>
            </div>

            <div>
                {data &&
                    data?.map((unsubscribe) => (
                        <div
                            onClick={() => deleteUnsubscribeClickHandler(unsubscribe.id)}
                            key={unsubscribe.id}
                            className={CnUnsubscribe('item')}
                        >
                            {unsubscribe.unsubscribe}

                            <Trash />
                        </div>
                    ))}
            </div>
        </div>
    );
};
