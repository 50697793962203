import React from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Plus } from 'assets';

import { Button, GroupsTable, Title } from 'components';

import './Groups.scss';

const CnGroups = cn('groups');

export const Groups: React.FC = () => {
    const navigate = useNavigate();

    const addGroupHandler = () => {
        navigate('?modal=add-group');
    };

    return (
        <div className={CnGroups()}>
            <div className={CnGroups('header')}>
                <Title size="m">Группы шаблонов</Title>

                <Button onClick={addGroupHandler} view="secondary" size="m">
                    <Plus /> Добавить
                </Button>
            </div>

            <div className={CnGroups('content')}>
                <GroupsTable />
            </div>
        </div>
    );
};
