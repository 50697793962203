/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { itemsPerPageCount } from 'constants/pagination';

import React, { useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Moment } from 'moment';
import { fetchLogs, setLogsCurrentPage, setLogsSort } from 'store/action/logs';
import { useAppDispatch, useAppSelector } from 'store/store';
import { FetchStatus, Sort } from 'types/api';

import { Block, Select, Table, TableHeaderItem } from 'components';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { Pagination } from 'components/Pagination/Pagination';

import { LogsTableItem } from './LogsTableItem/LogsTableItem';

import './LogsTable.scss';

const CnLogsTable = cn('logsTable');

export const LogsTable: React.FC<{ id: string }> = ({ id }) => {
    const { data, fetchStatus, itemsCount, currentPage, sort } = useAppSelector((store) => store.logs);
    const dispatch = useAppDispatch();
    const pagesCount = useMemo(() => Math.ceil(itemsCount / itemsPerPageCount), [itemsCount]);
    const [date, setDate] = useState<[Moment, Moment]>();

    const dateChangeHandler: any = (values: [Moment, Moment]) => {
        setDate(values);
    };

    useEffect(() => {
        void dispatch(
            fetchLogs({
                id,
                offset: (currentPage - 1) * itemsPerPageCount,
                from: date ? date[0].format('YYYY-MM-DD 00:00') : '',
                to: date ? date[1].format('YYYY-MM-DD 23:59') : '',
                sort: sort,
            }),
        );
    }, [dispatch, id, currentPage, date, sort]);

    const pageClickHandler = (page: number) => {
        dispatch(setLogsCurrentPage(page));
    };

    const sortChangeCallback = (value: unknown) => {
        const newValue = value as { value: Sort };
        dispatch(setLogsSort(newValue.value));
    };

    return (
        <Block>
            <div style={{ marginBottom: 32, display: 'flex' }}>
                <div className={CnLogsTable('searchItem')}>
                    <Select
                        placeholder="Сортировать по дате"
                        value={
                            {
                                asc: {
                                    label: 'По возрастанию',
                                    value: 'asc',
                                },
                                desc: {
                                    label: 'По убыванию',
                                    value: 'desc',
                                },
                            }[String(sort)]
                        }
                        onChange={sortChangeCallback}
                        options={[
                            {
                                label: 'По возрастанию',
                                value: 'asc',
                            },
                            {
                                label: 'По убыванию',
                                value: 'desc',
                            },
                        ]}
                    />
                </div>

                <DatePicker values={date} onChange={dateChangeHandler} />
            </div>
            <div className={CnLogsTable('scroll')}>
                <Table className={CnLogsTable()}>
                    <thead>
                        <tr>
                            <TableHeaderItem>Время</TableHeaderItem>
                            <TableHeaderItem>Действие</TableHeaderItem>
                        </tr>
                    </thead>

                    <tbody>
                        {fetchStatus === FetchStatus.FETCHED ? (
                            data ? (
                                data.map((log) => <LogsTableItem key={log.date} {...log} />)
                            ) : (
                                <div style={{ padding: 15 }}>Действия отсутствуют</div>
                            )
                        ) : null}
                    </tbody>
                </Table>
            </div>

            <Pagination pagesCount={pagesCount} pageClickHandler={pageClickHandler} currentPage={currentPage} />
        </Block>
    );
};
