/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchSendedMessages, setSendedMessagesPage, setSendedMessagesSearch } from 'store/action/sendedMessages';
import { FetchStatus, Sort } from 'types/api';

import { Tag } from './tags';

export interface SendedMessage {
    date: string;
    externalUserId: string;
    id: string;
    message: string;
    read: boolean;
    tags: Tag[];
    title: string;
    userId: string;
}

export interface SendedMessagesState {
    fetchStatus: FetchStatus;
    error: unknown;
    sendedMessages: Array<SendedMessage> | null;
    search: {
        tag: string;
        search: string;
        dateFrom: string;
        dateTo: string;
        sort: Sort | null;
    },
    currentPage: number;
    itemsCount: number;
}

const initialState: SendedMessagesState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    sendedMessages: null,
    search: {
        tag: "",
        search: "",
        dateFrom: "",
        dateTo: "",
        sort: null
    },
    currentPage: 1,
    itemsCount: 0
};

export const sendedMessagesSlice = createSlice<SendedMessagesState, SliceCaseReducers<SendedMessagesState>>({
    name: 'sendedMessages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSendedMessages.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchSendedMessages.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.sendedMessages = payload.messages;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchSendedMessages.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(setSendedMessagesPage, (state, { payload }) => {
            state.currentPage = payload;
        });

        builder.addCase(setSendedMessagesSearch, (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const anyPayload: any = payload;
            const newSearch = JSON.parse(JSON.stringify(state.search));

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            newSearch[anyPayload.key] = anyPayload.value;

            state.search = newSearch;
        });
    },
});

export const sendedMessagesReducer = sendedMessagesSlice.reducer;
