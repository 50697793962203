import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchChangeTemplate } from 'store/action/templates';
import { fetchRestoreTemplate, fetchTemplatesHistory, setTemplatesHistoryPage, setTemplatesHistoryTemplate } from 'store/action/templatesHistory';
import { FetchStatus } from 'types/api';

import { Template } from './templates';

export interface TemplatesHistoryState {
    fetchStatus: FetchStatus;
    data: Array<Template> | null;
    error: unknown;
    currentPage: number;
    itemsCount: number;
    template: Template | null | { id: string };
}

const initialState: TemplatesHistoryState = {
    fetchStatus: FetchStatus.INITIAL,
    data: null,
    error: null,
    currentPage: 1,
    itemsCount: 0,
    template: null,
};

export const templatesHistorySlice = createSlice<TemplatesHistoryState, SliceCaseReducers<TemplatesHistoryState>>({
    name: 'templatesHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTemplatesHistory.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchTemplatesHistory.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.templateHistorys;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchTemplatesHistory.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchRestoreTemplate.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchRestoreTemplate.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.templateHistorys;
            state.itemsCount = payload.itemsCount;
            state.template = payload.template;
        });

        builder.addCase(fetchChangeTemplate.fulfilled, (state, { payload }) => {
            state.template = payload.template;
        })

        builder.addCase(fetchRestoreTemplate.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(setTemplatesHistoryTemplate, (state, { payload }) => {
            state.template = payload;
        });

        builder.addCase(setTemplatesHistoryPage, (state, { payload }) => {
            state.currentPage = payload;
        });
    },
});

export const templatesHistoryReducer = templatesHistorySlice.reducer;
