import { host } from "constants/api"

import axios, { AxiosResponse } from "axios"
import { Group } from "store/reducer/groups";
import { Response } from "types/api";



interface FetchGroupsResponse extends Response {
    templateGroups: Group[];
    countItems: number;
}

export const fetchGroupsRequest = async (payload: { offset: number, limit: number, search?: string }): Promise<FetchGroupsResponse> => {
    return await axios.get<never, AxiosResponse<FetchGroupsResponse>>(`${host}/admin/template/group/all`, {
        params: {
            limit: payload.limit,
            offset: payload.offset,
            search: payload.search
        }
    }).then(res => res.data)
}

interface FetchAddGroupsResponse extends Response {
    templateGroup: Group[];
}

export const fetchAddGroupsRequest = async (name: string): Promise<FetchAddGroupsResponse> => {
    return await axios.post<never, AxiosResponse<FetchAddGroupsResponse>>(`${host}/admin/template/group`, { 
        name
    }).then(res => res.data)
}

interface FetchChangeGroupsResponse extends Response {
    templateGroup: Group[];
}

export const fetchChangeGroupsRequest = async (payload: { name: string, templateGroupId: string }): Promise<FetchChangeGroupsResponse> => {
    return await axios.put<never, AxiosResponse<FetchChangeGroupsResponse>>(`${host}/admin/template/group`, payload).then(res => res.data)
}

export const fetchDeleteGroupsRequest = async (payload: { templateGroupId: string }): Promise<Response> => {
    return await axios.post<never, AxiosResponse<Response>>(`${host}/admin/template/group/delete`, payload).then(res => res.data)
}