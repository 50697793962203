import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchTags } from 'store/action/tags';
import { FetchStatus } from 'types/api';

export interface Tag {
    id: string;
    name: string;
}

export interface TagsState {
    fetchStatus: FetchStatus;
    error: unknown;
    tags: Array<Tag> | null;
}

const initialState: TagsState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    tags: null,
};

export const tagsSlice = createSlice<TagsState, SliceCaseReducers<TagsState>>({
    name: 'tags',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTags.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchTags.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.tags = payload.tags;
        });

        builder.addCase(fetchTags.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });
    },
});

export const tagsReducer = tagsSlice.reducer;
