import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';

import './Pagination.scss';

const CnPagination = cn('pagination');

interface IPaginationProps {
    pagesCount: number;
    pageClickHandler: (page: number) => void;
    currentPage: number;
}

export const Pagination: React.FC<IPaginationProps> = ({ pageClickHandler, pagesCount, currentPage }) => {
    const render = useMemo(() => {
        const arr = new Array(pagesCount).fill(0);

        return arr.map((el, index) => (
            <div
                key={index}
                onClick={() => pageClickHandler(index + 1)}
                className={CnPagination('item', { selected: currentPage === index + 1 })}
            >
                {index + 1}
            </div>
        ));
    }, [pagesCount, currentPage, pageClickHandler]);

    const leftClickHandler = () => {
        if (currentPage > 1) {
            pageClickHandler(currentPage - 1);
        }
    };

    const rightClickHandler = () => {
        if (currentPage < pagesCount) {
            pageClickHandler(currentPage + 1);
        }
    };

    return (
        <div className={CnPagination()}>
            <div onClick={leftClickHandler} className={CnPagination('item')}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.8417 6.175L9.02501 10L12.8417 13.825L11.6667 15L6.66668 10L11.6667 5L12.8417 6.175Z"
                        fill="#201D1D"
                    />
                </svg>
            </div>

            {render}

            <div onClick={rightClickHandler} className={CnPagination('item')}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.15833 13.825L10.975 10L7.15833 6.175L8.33332 5L13.3333 10L8.33332 15L7.15833 13.825Z"
                        fill="#201D1D"
                    />
                </svg>
            </div>
        </div>
    );
};
