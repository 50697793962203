import { itemsPerPageCount } from 'constants/pagination';

import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchReceivers, setReceiversPage, setReceiversSearch, setReceiversSort } from 'store/action/receivers';
import { useAppSelector } from 'store/store';

import { Block, Input, Pagination, Select, Table, TableHeaderItem } from 'components';

import { ReceiversTableItem } from './ReceiversTableItem/ReceiversTableItem';

import './ReceiversTable.scss';

const CnReceiversTable = cn('receiversTable');

export const ReceiversTable: React.FC = () => {
    const { receivers, currentPage, itemsCount, search, sort } = useAppSelector((store) => store.receivers);
    const pagesCount = useMemo(() => Math.ceil(itemsCount / itemsPerPageCount), [itemsCount]);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (!location.search) {
            void dispatch(fetchReceivers({ offset: (currentPage - 1) * itemsPerPageCount, search, sort }));
        }
    }, [dispatch, currentPage, search, sort, location.search]);

    const pageClickHandler = (page: number) => {
        dispatch(setReceiversPage(page));
    };

    const searchChangeCallback = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setReceiversSearch(e.target.value));
    };

    const sortChangeCallback = (value: unknown) => {
        const newValue = value as { value: 'asc' | 'desc' };
        dispatch(setReceiversSort(newValue.value));
    };

    return (
        <Block>
            <div className={CnReceiversTable('search')}>
                <Select
                    placeholder="Сортировать по ID"
                    value={
                        {
                            asc: {
                                label: 'По возрастанию',
                                value: 'asc',
                            },
                            desc: {
                                label: 'По убыванию',
                                value: 'desc',
                            },
                        }[String(sort)]
                    }
                    onChange={sortChangeCallback}
                    options={[
                        {
                            label: 'По возрастанию',
                            value: 'asc',
                        },
                        {
                            label: 'По убыванию',
                            value: 'desc',
                        },
                    ]}
                />
                <Input placeholder="Введите Id" value={search} onChange={searchChangeCallback} />
            </div>

            <Table className={CnReceiversTable()}>
                <thead>
                    <tr>
                        <TableHeaderItem>ID</TableHeaderItem>
                        <TableHeaderItem>Имя</TableHeaderItem>
                        <TableHeaderItem>Последнее сообщение</TableHeaderItem>
                        <TableHeaderItem>Отписок</TableHeaderItem>
                        <TableHeaderItem>Действия</TableHeaderItem>
                    </tr>
                </thead>

                <tbody>
                    {receivers &&
                        receivers.map((receivers) => <ReceiversTableItem key={receivers.id} {...receivers} />)}
                </tbody>
            </Table>

            <Pagination pagesCount={pagesCount} pageClickHandler={pageClickHandler} currentPage={currentPage} />
        </Block>
    );
};
