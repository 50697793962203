import React from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Delete } from 'assets';
import { Platform } from 'store/reducer/platforms';

import { TableItem } from 'components';

import './PlatformsTableItem.scss';

const CnPlatformsTableItem = cn('platformsTableItem');

export const PlatformsTableItem: React.FC<Platform> = ({ id, token, name }) => {
    const navigate = useNavigate();

    const deletePlatformHandler = () => {
        navigate('?modal=delete-platform', {
            state: {
                id,
                token,
                name,
            },
        });
    };

    return (
        <tr className="platformsTableItem">
            <TableItem>{id}</TableItem>
            <TableItem>{name}</TableItem>
            <TableItem>{token}</TableItem>
            <TableItem>
                <div className={CnPlatformsTableItem('actions')}>
                    <div className={CnPlatformsTableItem('action')} onClick={deletePlatformHandler}>
                        <Delete className={CnPlatformsTableItem('icon')} />
                        Удалить
                    </div>
                </div>
            </TableItem>
        </tr>
    );
};
