import React from 'react';
import { cn } from '@bem-react/classname';
import { Logo as LogoIcon } from 'assets';

import './Logo.scss';

const CnLogo = cn('logo');

interface ILogoProps {
    size?: 'default';
}

export const Logo: React.FC<ILogoProps> = ({ size = 'default' }) => {
    return (
        <div className={CnLogo({ size })}>
            <LogoIcon />

            <div className={CnLogo('content')}>
                <div className={CnLogo('title')}>Mobile app</div>

                <div className={CnLogo('subtitle')}>Admin panel</div>
            </div>
        </div>
    );
};
