import { host } from 'constants/api';

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Response } from 'types/api';

import { getAccessToken, getRefreshToken } from 'utils/token';

axios.interceptors.request.use(
    (config) => {
        const token = getAccessToken();

        if (token) {
            (config.headers as Record<string, string>)['Authorization'] = token;
        }

        return config;
    },
    (error) => {
        Promise.reject(error).catch((res: { status: string }) => {
            throw res?.status;
        });
    },
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError<never>) => {
        const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };
        const refresh = getRefreshToken();

        if (refresh) {
            if (error.response?.status === 403 || error.response?.status == 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const access = getAccessToken();
    
                return axios
                    .post<Promise<{ access: string }>, AxiosResponse<{ tokens: { access: string, refresh: string } }>>(`${host}/auth/admin/refresh`, {
                        refresh,
                        access
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            localStorage.setItem('access', res?.data.tokens.access);
                            localStorage.setItem('refresh', res?.data.tokens.refresh);
                            return axios(originalRequest);
                        }
                    });
            }
        } else {
            window.location.href = '/auth'
        }

        return Promise.reject(error).catch((res: { status: string }) => {
            throw res?.status;
        });
    },
);

interface AuthResponse extends Response {
    tokens: {
        access: string;
        refresh: string;
    };
    name: string;
}

export const fetchAuthRequest = async (token: string): Promise<AuthResponse> => {
    return await axios
        .post<never, AxiosResponse<AuthResponse>>(`${host}/auth/admin/login`, { token })
        .then((res) => res.data);
};

interface AccessesResponse extends Response {
    adminRules: Record<string, 1 | 2>;
}

export const fetchAccessesRequest = async (): Promise<AccessesResponse> => {
    return await axios
        .get<never, AxiosResponse<AccessesResponse>>(`${host}/admin/admins/rules`)
        .then((res) => res.data);
};
