import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchChangeTemplateRequest, fetchRestoreTemplateRequest, fetchTemplatesHistoryRequest } from "api/templates";
import { Template } from "store/reducer/templates";


export const fetchTemplatesHistory = createAsyncThunk("@templatesHistory/fetch", async (data: { id: string, offset: number }) => {
    return await fetchTemplatesHistoryRequest(data.id, data.offset);
});

export const fetchRestoreTemplate = createAsyncThunk("@templatesHistory/restore", async (data: { templateHistoryId: string, offset: number, id: string }) => {
    const template = await fetchRestoreTemplateRequest(data.templateHistoryId);
    const templateHistorys = await fetchTemplatesHistoryRequest(data.id, data.offset)
    return {
        template: template.template,
        templateHistorys: templateHistorys.templateHistorys,
        itemsCount: templateHistorys.countItems,
    }
});

export const fetchChangeTemplateInHistory = createAsyncThunk(
    '@templatesHistory/change',
    async ({ templateId, data, offset }: { templateId: string; data: unknown, offset: number }) => {
        const template = await fetchChangeTemplateRequest(templateId, data);
        const templateHistorys = await fetchTemplatesHistoryRequest(templateId, offset);

        return {
            template: template.template,
            templateHistorys: templateHistorys.templateHistorys,
            itemsCount: templateHistorys.countItems,
        }
    },
);

export const setTemplatesHistoryPage = createAction<number>("@templatesHistory/setPage");
export const setTemplatesHistoryTemplate = createAction<Template | { id: string }>("@templatesHistory/setTemplate");