import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchDeleteTemplate } from 'store/action/templates';

import { Button, Subtitle, Title } from 'components';

import './DeleteTemplate.scss';

const CnDeleteTemplate = cn('deleteTemplate');

export const DeleteTemplate: React.FC = () => {
    const location = useLocation();
    const state = location.state as {
        comment: string;
        id: string;
        message: string;
        messageTitle: string;
        name: string;
    };

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const deleteClickHandler = () => {
        navigate(location.pathname);

        dispatch(fetchDeleteTemplate(state.id));
    };

    const cancelClickHandler = () => {
        navigate(location.pathname, { state });
    };

    return (
        <div className={CnDeleteTemplate()}>
            <div className={CnDeleteTemplate('header')}>
                <Title size="xs">Удалить шаблон</Title>
                <Subtitle size="m">{state?.name}</Subtitle>
            </div>

            <div className={CnDeleteTemplate('actions')}>
                <Button onClick={deleteClickHandler} view="primary" size="m">
                    Удалить
                </Button>

                <Button onClick={cancelClickHandler} view="secondary" size="m">
                    Отмена
                </Button>
            </div>
        </div>
    );
};
