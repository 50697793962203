import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAddGroups, fetchChangeGroups, fetchDeleteGroups, fetchGroups, setGroupsCurrentPage, setGroupsSearch } from 'store/action/groups';
import { FetchStatus } from 'types/api';

export interface Group {
    id: string;
    name: string;
    description: string;
}

export interface GroupState {
    fetchStatus: FetchStatus;
    error: unknown;
    data: Array<Group> | null;
    itemsCount: number;
    currentPage: number;
    search: string;
}

const initialState: GroupState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    data: null,
    itemsCount: 0,
    currentPage: 1,
    search: ''
};

export const groupSlice = createSlice<GroupState, SliceCaseReducers<GroupState>>({
    name: 'group',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGroups.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchGroups.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.templateGroups;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchGroups.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchAddGroups.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAddGroups.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.templateGroups;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchChangeGroups.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchChangeGroups.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchChangeGroups.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.templateGroups;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchDeleteGroups.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchDeleteGroups.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchDeleteGroups.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.templateGroups;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchAddGroups.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(setGroupsCurrentPage, (state, { payload }) => {
            state.currentPage = payload;
        });

        builder.addCase(setGroupsSearch, (state, { payload }) => {
            state.currentPage = 1;

            state.search = payload;
        });
    },
});

export const groupsReducer = groupSlice.reducer;
