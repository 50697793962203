import { host } from "constants/api"

import axios, { AxiosResponse } from "axios"
import { Unsubscribe } from "store/reducer/unsubscribes";

interface UnsubscribesResponse extends Response {
    unsubscribes: Unsubscribe[];
}

export const fetchUnsubscribesRequest = async (userId: string): Promise<UnsubscribesResponse> => {
    return await axios.get<never, AxiosResponse<UnsubscribesResponse>>(`${host}/admin/user/unsubscribe?userId=${userId}`).then(res => res.data);
}

interface AddUnsubscribeResponse extends Response {
    unsubscribe: Unsubscribe;
}

export const fetchAddUnsubscribeRequest = async (data: { userId: string, unsubscribe: string }): Promise<AddUnsubscribeResponse> => {
    return await axios.post<never, AxiosResponse<AddUnsubscribeResponse>>(`${host}/admin/user/unsubscribe`, data).then(res => res.data);
}


type DeleteUnsubscribeResponse = Response;

export const fetchDeleteUnsubscribeRequest = async (data: { unsubscribeId: string }): Promise<DeleteUnsubscribeResponse> => {
    return await axios.post<never, AxiosResponse<DeleteUnsubscribeResponse>>(`${host}/admin/user/unsubscribe/delete`, data).then(res => res.data);
}