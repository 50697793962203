import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Copy, Delete, EyeEmpty, LockOpen, Refresh } from 'assets';
import moment from 'moment';
import { fetchUpdateTokenAdmin } from 'store/action/admins';
import { Admin } from 'store/reducer/admins';

import { TableItem } from 'components';

import './AdminsTableItem.scss';

type IAdminsTableItemProps = Admin;

const CnAdminsTableItem = cn('adminsTableItem');

export const AdminsTableItem: React.FC<IAdminsTableItemProps> = ({ name, token, id, adminRules, lastTimeUse }) => {
    const navigate = useNavigate();

    const {
        adminRule,
        id: rulesId,
        eventRule,
        messageRule,
        platformRule,
        templateRule,
        userRule,
        variableRule,
    } = adminRules;

    const [isTokenShow, setIsTokenShow] = useState(false);
    const dispatch = useDispatch();

    const isTokenShowChangeCallback = useCallback(
        (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            e.stopPropagation();

            setIsTokenShow(!isTokenShow);
        },
        [isTokenShow],
    );

    const deleteAdminModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        navigate(`?modal=delete-admin`, { state: { id, name, token } });
    };

    const changeAccessModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        navigate(`?modal=change-access`, {
            state: {
                id,
                name,
                token,
                rulesId,
                rules: {
                    adminRule,
                    eventRule,
                    messageRule,
                    platformRule,
                    templateRule,
                    userRule,
                    variableRule,
                },
            },
        });
    };

    const updateAdminTokenHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();

        dispatch(fetchUpdateTokenAdmin(id));
    };

    const copyClickHandler = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.stopPropagation();

        void navigator.clipboard.writeText(token).then(() => console.log('success'));
    };

    const rowClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();

        navigate(`/admins/logs`, {
            state: {
                id,
                name,
                token,
                rulesId,
                rules: {
                    adminRule,
                    eventRule,
                    messageRule,
                    platformRule,
                    templateRule,
                    userRule,
                    variableRule,
                },
            },
        });
    };

    return (
        <tr onClick={rowClickHandler} className={CnAdminsTableItem()}>
            <TableItem>{name}</TableItem>
            <TableItem>{lastTimeUse ? moment(lastTimeUse).format('DD MMMM YYYY HH:mm') : 'Отсутствует'}</TableItem>
            <TableItem>
                <div className={CnAdminsTableItem('token')}>
                    {isTokenShow ? token : '••••••••••••••••••••••••••••••'}

                    <EyeEmpty onClick={isTokenShowChangeCallback} className={CnAdminsTableItem('icon')} />
                    <Refresh onClick={updateAdminTokenHandler} className={CnAdminsTableItem('icon')} />
                    <Copy onClick={copyClickHandler} className={CnAdminsTableItem('icon')} />
                </div>
            </TableItem>
            <TableItem>
                <div className={CnAdminsTableItem('actions')}>
                    <div className={CnAdminsTableItem('action')} onClick={changeAccessModal}>
                        <LockOpen className={CnAdminsTableItem('icon')} />
                        Доступы
                    </div>

                    <div className={CnAdminsTableItem('action')} onClick={deleteAdminModal}>
                        <Delete className={CnAdminsTableItem('icon')} />
                        Удалить
                    </div>
                </div>
            </TableItem>
        </tr>
    );
};
