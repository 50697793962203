import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'store/store';

export const PrivateRoute: React.FC = ({ children }) => {
    const { isLoggedIn } = useAppSelector((state) => state.auth);

    if (!isLoggedIn) {
        return <Navigate to="/auth" />;
    }

    return <>{children}</>;
};
