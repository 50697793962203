/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { host } from 'constants/api';
import { itemsPerPageCount } from 'constants/pagination';

import axios, { AxiosResponse } from 'axios';
import { Message } from 'store/reducer/messages';
import { Response, Sort } from 'types/api';

interface MessagesResponse extends Response {
    messages: Message[];
    countItems: number;
}

export const fetchMessagesRequest = async (payload: { userId: string, search: unknown, offset: number, dateFrom: string, dateTo: string, sort: Sort | null }): Promise<MessagesResponse> => {
    let str = '';
    const search = JSON.parse(JSON.stringify(payload.search))

    if (search['tag']) {
        str += `tagId=${search.tag.value}&`
    }

    if (search['text']) {
        str += `search=${search.text}&`
    }

    if (search['dateFrom']) {
        str += `dateFrom=${payload.dateFrom}&`
    }

    if (search['dateTo']) {
        str += `dateFrom=${payload.dateTo}&`
    }

    if (search['sort']) {
        str += `sortBy=date.${payload.sort}&`
    }

    return await axios
        .get<never, AxiosResponse<MessagesResponse>>(`${host}/admin/user/messages?userId=${payload.userId}&limit=${itemsPerPageCount}&offset=${payload.offset}&${str}`)
        .then((res) => res.data);
};
