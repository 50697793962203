import React from 'react';
import { cn } from '@bem-react/classname';
import moment from 'moment';
import { Log } from 'store/reducer/logs';

import { TableItem } from 'components';

import './LogsTableItem.scss';

const CnLogsTableItem = cn('logsTableItem');

export const LogsTableItem: React.FC<Log> = ({ date, log }) => {
    return (
        <tr className={CnLogsTableItem()}>
            <TableItem>{moment(date).format('DD MMMM YYYY HH:mm')}</TableItem>
            <TableItem>{log}</TableItem>
        </tr>
    );
};
