import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAddVariableRequest, fetchChangeVariableRequest, fetchDeleteVariableRequest, fetchVariablesRequest } from "api/variables";


export const fetchVariables = createAsyncThunk("@variables/fetch", async () => {
    return await fetchVariablesRequest();
})

export const fetchAddVariable = createAsyncThunk("@variables/add", async (data: {name: string, value: string}) => {
    await fetchAddVariableRequest(data);
    
    return await fetchVariablesRequest();
})


export const fetchChangeVariable = createAsyncThunk("@variables/change", async (data: {name: string, value: string, variableId: string}) => {
    await fetchChangeVariableRequest(data);
    
    return await fetchVariablesRequest();
})


export const fetchDeleteVariable = createAsyncThunk("@variables/delete", async (variableId: string) => {
    await fetchDeleteVariableRequest(variableId);
    
    return await fetchVariablesRequest();
})