/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchTemplateRequest } from 'api/templates';
import { Delete, Pen } from 'assets';
import { fetchDeleteTemplate } from 'store/action/templates';
import { Template } from 'store/reducer/templates';
import { useAppSelector } from 'store/store';

import { TemplatesHistoryTable, Title } from 'components';

import './TemplatesHistory.scss';

const CnTemplatesHistory = cn('templatesHistory');

export const TemplatesHistory: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { template } = useAppSelector((store) => store.templatesHistory);
    const [myTemplate, setTemplate] = useState<Template | null>(null);

    useEffect(() => {
        const newTemplate: unknown = template;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const anyTemplate = newTemplate as any;
        if (anyTemplate?.name) {
            const typedTemplate = anyTemplate as Template;
            setTemplate(typedTemplate);
        } else {
            void fetchTemplateRequest(anyTemplate?.id).then((res) => {
                setTemplate(res.template);
            });
        }
    }, [template]);

    useEffect(() => {
        if (!template || !template.id) {
            navigate('/templates');
        }
    }, [template, navigate]);

    const changeTemplateHandler = () => {
        navigate('?modal=change-template', {
            state: myTemplate,
        });
    };

    const deleteTemplateHandler = () => {
        if (myTemplate) {
            dispatch(fetchDeleteTemplate(myTemplate?.id ?? ''));
            navigate('/templates');
        }
    };

    if (!myTemplate) {
        return null;
    }

    return (
        <div className={CnTemplatesHistory()}>
            <div className={CnTemplatesHistory('header')}>
                <div onClick={() => navigate('/templates')} className={CnTemplatesHistory('back')}>
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.8415 8.825L3.02484 5L6.8415 1.175L5.6665 0L0.666504 5L5.6665 10L6.8415 8.825Z"
                            fill="#969595"
                        />
                    </svg>
                    Шаблоны
                </div>
                <Title size="m">{myTemplate?.name}</Title>

                <div className={CnTemplatesHistory('row')}>
                    <div className={CnTemplatesHistory('text')}>Шаблон</div>
                    <div className={CnTemplatesHistory('actions')}>
                        <div onClick={changeTemplateHandler} className={CnTemplatesHistory('action')}>
                            <Pen className={CnTemplatesHistory('icon')} />
                            Изменить
                        </div>

                        <div className={CnTemplatesHistory('action')} onClick={deleteTemplateHandler}>
                            <Delete className={CnTemplatesHistory('icon')} />
                            Удалить
                        </div>
                    </div>
                </div>
            </div>

            <div className={CnTemplatesHistory('template')}>
                <div className={CnTemplatesHistory('templateTitle')}>{myTemplate?.title}</div>

                <div className={CnTemplatesHistory('templateText')}>{myTemplate?.message}</div>
            </div>

            <div className={CnTemplatesHistory('content')}>
                {myTemplate ? <TemplatesHistoryTable myTemplate={myTemplate} /> : null}
            </div>
        </div>
    );
};
