import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchAddGroups } from 'store/action/groups';
import { useAppDispatch } from 'store/store';

import { Button, Input, Title } from 'components';

import './AddGroup.scss';

const CnAddGroup = cn('addAdmin');

export const AddGroup: React.FC = () => {
    const [name, setName] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const nameChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    const buttonClickHandler = () => {
        navigate(pathname);
        void dispatch(fetchAddGroups(name));
    };

    return (
        <div className={CnAddGroup()}>
            <div className={CnAddGroup('header')}>
                <Title size="xs">Добавить группу</Title>
            </div>

            <div className={CnAddGroup('input')}>
                <Input value={name} onChange={nameChangeCallback} label="Имя" placeholder="Введите имя" />
            </div>

            <div className={CnAddGroup('action')}>
                <Button onClick={buttonClickHandler} view="primary" size="m">
                    Добавить
                </Button>
            </div>
        </div>
    );
};
