import React from 'react';
import { cn } from '@bem-react/classname';
import moment from 'moment';
import { Message } from 'store/reducer/messages';

import { TableItem, Tag } from 'components';

import './MessagesTableItem.scss';

const CnMessagesTableItem = cn('messagesTableItem');

export const MessagesTableItem: React.FC<Message> = ({ message, title, date, read, tags }) => {
    return (
        <tr className={CnMessagesTableItem()}>
            <TableItem>{moment(date).format('MMM D, YYYY HH:mm')}</TableItem>
            <TableItem>
                <div className={CnMessagesTableItem('message')}>
                    <div className={CnMessagesTableItem('title')}>{title}</div>
                    <div className={CnMessagesTableItem('text')}>{message}</div>
                </div>
            </TableItem>
            <TableItem>{read ? 'Да' : 'Нет'}</TableItem>

            <TableItem>
                <div className={CnMessagesTableItem('tags')}>
                    {tags
                        ? tags.map((tag) => {
                              return <Tag key={tag.id} text={tag.name}></Tag>;
                          })
                        : 'Отсутствуют'}
                </div>
            </TableItem>
        </tr>
    );
};
