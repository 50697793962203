import React from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Delete, Pen } from 'assets';
import { Variable } from 'store/reducer/variables';

import { TableItem } from 'components';

import './VariablesTableItem.scss';

const CnVariablesTableItem = cn('variablesTableItem');

type IVariablesTableItemProps = Variable;

export const VariablesTableItem: React.FC<IVariablesTableItemProps> = ({ id, name, value }) => {
    const navigate = useNavigate();

    const deleteTemplateHandler = () => {
        navigate('?modal=delete-variable', {
            state: {
                id,
                name,
            },
        });
    };

    const changeTemplateHandler = () => {
        navigate('?modal=change-variable', {
            state: {
                id,
                name,
                value,
            },
        });
    };

    return (
        <tr>
            <TableItem>{name}</TableItem>
            <TableItem>{value}</TableItem>
            <TableItem>
                <div className={CnVariablesTableItem('actions')}>
                    <div onClick={changeTemplateHandler} className={CnVariablesTableItem('action')}>
                        <Pen className={CnVariablesTableItem('icon')} />
                        Изменить
                    </div>

                    <div className={CnVariablesTableItem('action')} onClick={deleteTemplateHandler}>
                        <Delete className={CnVariablesTableItem('icon')} />
                        Удалить
                    </div>
                </div>
            </TableItem>
        </tr>
    );
};
