import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';

import { LogsTable, Title } from 'components';

import { LogsInfo } from './LogsInfo/LogsInfo';

import './Logs.scss';

const CnLogs = cn('logs');

export const Logs: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const state = location.state as {
        id: string;
        name: string;
        token: string;
        rulesId: string;
        rules: Record<string, 1 | 2>;
    };

    const goBack = () => {
        navigate('/admins');
    };

    return (
        <div className={CnLogs()}>
            <div className={CnLogs('header')}>
                <div onClick={goBack} className={CnLogs('header-back')}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8417 13.825L9.02508 10L12.8417 6.175L11.6667 5L6.66675 10L11.6667 15L12.8417 13.825Z"
                            fill="#969595"
                        />
                    </svg>
                    Администраторы
                </div>
                <Title size="m">{state.name}</Title>
                {/* 
                <Button onClick={addAdminClickHandler} view="secondary" size="m">
                    <Plus /> Добавить
                </Button> */}

                <LogsInfo {...state} />
            </div>

            <div className={CnLogs('content')}>
                <LogsTable id={state.id} />
            </div>
        </div>
    );
};
