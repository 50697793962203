import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAddTemplate, fetchChangeTemplate, fetchDeleteTemplate, fetchTemplates, setTemplateGroup, setTemplatePage, setTemplateSearch, setTemplateSort } from 'store/action/templates';
import { FetchStatus, Sort } from 'types/api';

export interface Template {
    id: string;
    name: string;
    message: string;
    title: string;
    description: string;
    createdAt: string;
    templateGroupId: string;
}

export interface TemplateState {
    fetchStatus: FetchStatus;
    data: Array<Template> | null;
    error: unknown;
    currentPage: number;
    itemsCount: number;
    search: string;
    sort: Sort | null;
    group: {
        label: string;
        value: string;
    } | null;
}

const initialState: TemplateState = {
    fetchStatus: FetchStatus.INITIAL,
    data: null,
    error: null,
    currentPage: 1,
    itemsCount: 0,
    search: '',
    sort: null,
    group: null
};

export const templatesSlice = createSlice<TemplateState, SliceCaseReducers<TemplateState>>({
    name: 'templates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTemplates.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchTemplates.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.templates;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchTemplates.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchAddTemplate.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAddTemplate.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.templates;
            state.itemsCount = payload.countItems;

        });

        builder.addCase(fetchAddTemplate.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchDeleteTemplate.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchDeleteTemplate.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.templates;
            state.itemsCount = payload.countItems;

        });

        builder.addCase(fetchDeleteTemplate.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchChangeTemplate.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchChangeTemplate.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.templates;
            state.itemsCount = payload.countItems;

        });

        builder.addCase(fetchChangeTemplate.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(setTemplatePage, (state, { payload }) => {
            state.currentPage = payload;
        });

        builder.addCase(setTemplateSearch, (state, { payload }) => {
            state.search = payload;
            state.currentPage = 1;
        });

        builder.addCase(setTemplateSort, (state, { payload }) => {
            state.sort = payload;
            state.currentPage = 1;
        });

        builder.addCase(setTemplateGroup, (state, { payload }) => {
            state.group = payload;
            state.currentPage = 1;
        });

    },
});

export const templatesReducer = templatesSlice.reducer;
