import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { Logout } from 'assets';
import { logout } from 'store/action/auth';
import { useAppSelector } from 'store/store';

import { Logo } from 'components';

import './Header.scss';

const CnHeader = cn('header');

interface IHeaderProps {
    withNavigation?: boolean;
    withProfile?: boolean;
}

const navItems: Array<{
    title: string;
    path: string;
    accessField: string;
}> = [
    {
        title: 'Платформы',
        path: '/platforms',
        accessField: 'platformRule',
    },
    {
        title: 'Администраторы',
        path: '/admins',
        accessField: 'adminRule',
    },
    {
        title: 'События',
        path: '/events',
        accessField: 'eventRule',
    },
    {
        title: 'Шаблоны',
        path: '/templates',
        accessField: 'templateRule',
    },
    {
        title: 'Сообщения',
        path: '/sended',
        accessField: 'messageRule',
    },
    {
        title: 'Получатели',
        path: '/receivers',
        accessField: 'userRule',
    },
    {
        title: 'Переменные',
        path: '/variables',
        accessField: 'variableRule',
    },
];

export const Header: React.FC<IHeaderProps> = ({ withNavigation = true, withProfile = true }) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const {
        login: { name = '' },
        access,
    } = useAppSelector((store) => store.auth);

    const logoutClickHandler = () => {
        dispatch(logout());
    };

    return (
        <div className={CnHeader({ withNavigation, withProfile })}>
            <div className={CnHeader('item')}>
                <Logo />
            </div>

            {withNavigation ? (
                <div className={`${CnHeader('navigation')} ${CnHeader('item')}`}>
                    {navItems.map((item, index) => {
                        if (access[item.accessField] === 1) {
                            return (
                                <Link
                                    className={
                                        location.pathname.includes(item.path)
                                            ? CnHeader('navigation', { checked: true })
                                            : ''
                                    }
                                    to={item.path}
                                    key={index}
                                >
                                    {item.title}
                                </Link>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            ) : (
                <div></div>
            )}

            {withProfile ? (
                <div className={`${CnHeader('profile')} ${CnHeader('item')}`}>
                    <div className={CnHeader('name')}>{name}</div>

                    <div onClick={logoutClickHandler} className={CnHeader('icon')}>
                        <Logout />
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};
