import { host } from "constants/api"

import axios, { AxiosResponse } from "axios"
import { Variable } from "store/reducer/variables"
import { Response } from "types/api"

interface VariablesResponse extends Response {
    variables: Variable[];
}

export const fetchVariablesRequest = async (): Promise<VariablesResponse> => {
    return await axios.get<never, AxiosResponse<VariablesResponse>>(`${host}/admin/variable/all`).then(res => res.data)
}

interface VariableResponse extends Response {
    variable: Variable;
}

export const fetchAddVariableRequest = async (data: {name: string, value: string}): Promise<VariableResponse> => {
    return await axios.post<never, AxiosResponse<VariableResponse>>(`${host}/admin/variable`, data).then(res => res.data)
}

export const fetchChangeVariableRequest = async (data: {variableId: string, name: string, value: string}): Promise<VariableResponse> => {
    return await axios.put<never, AxiosResponse<VariableResponse>>(`${host}/admin/variable/update`, data).then(res => res.data)
}


export const fetchDeleteVariableRequest = async (variableId: string): Promise<VariableResponse> => {
    return await axios.post<never, AxiosResponse<VariableResponse>>(`${host}/admin/variable/delete`, { variableId }).then(res => res.data)
}