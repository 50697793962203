/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { fetchTags } from 'store/action/tags';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

import { Input, Select } from 'components';

const CnMessagesSearch = cn('messagesSearch');

import moment, { Moment } from 'moment';
import { setMessagesSearch } from 'store/action/messages';

import { DatePicker } from 'components/DatePicker/DatePicker';

import './MessagesSearch.scss';

export const MessagesSearch: React.FC = () => {
    const { tags, fetchStatus } = useAppSelector((store) => store.tags);
    const { search } = useAppSelector((store) => store.messages);
    const dates: any = [search.dateFrom ? moment(search.dateFrom) : '', search.dateTo ? moment(search.dateTo) : ''];
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTags());
    }, [dispatch]);

    const onChange = (key: string, value: unknown) => {
        dispatch(setMessagesSearch({ key, value }));
    };

    const dateChangeHandler: any = (values: [Moment, Moment]) => {
        dispatch(setMessagesSearch({ key: 'dateFrom', value: values[0].format('YYYY-MM-DD 00:00') }));
        dispatch(setMessagesSearch({ key: 'dateTo', value: values[1].format('YYYY-MM-DD 23:59') }));
    };

    const option = useMemo(() => {
        const result = [{ value: '', label: 'Не выбрано' }];

        if (fetchStatus === FetchStatus.FETCHED && tags) {
            result.push(...tags.map((tag) => ({ value: tag.id, label: tag.name })));
        }

        return result;
    }, [tags, fetchStatus]);

    return (
        <div className={CnMessagesSearch()}>
            <div className={CnMessagesSearch('item')}>
                <Select
                    placeholder="Сортировать по дате"
                    value={
                        {
                            asc: {
                                label: 'По возрастанию',
                                value: 'asc',
                            },
                            desc: {
                                label: 'По убыванию',
                                value: 'desc',
                            },
                        }[String(search.sort)]
                    }
                    onChange={(value) => {
                        const newValue = value as { value: string };
                        onChange('sort', newValue.value);
                    }}
                    options={[
                        {
                            label: 'По возрастанию',
                            value: 'asc',
                        },
                        {
                            label: 'По убыванию',
                            value: 'desc',
                        },
                    ]}
                />
            </div>

            <div className={CnMessagesSearch('item')}>
                <Select
                    options={option}
                    placeholder="Тег"
                    value={search.tag ? search.tag : null}
                    isLoading={fetchStatus === FetchStatus.FETCHING}
                    onChange={(value) => onChange('tag', value)}
                ></Select>
            </div>

            <div className={CnMessagesSearch('item')}>
                <DatePicker values={dates} onChange={dateChangeHandler} />
            </div>

            <div className={CnMessagesSearch('item')}>
                <Input onChange={(e) => onChange('text', e.target.value)} placeholder="Искать" />
            </div>
        </div>
    );
};
