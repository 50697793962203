import React from 'react';
import { cn } from '@bem-react/classname';

import { SendedMessagesTable, Title } from 'components';

import './SendedMessages.scss';

const CnSendedMessages = cn('sendedMessages');

export const SendedMessages: React.FC = () => {
    return (
        <div className={CnSendedMessages()}>
            <div className={CnSendedMessages('header')}>
                <Title size="m">Отправленые сообщения</Title>
            </div>

            <div className={CnSendedMessages('content')}>
                <SendedMessagesTable />
            </div>
        </div>
    );
};
