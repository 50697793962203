/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { DatePicker as ADatePicker } from 'antd';

import './DatePicker.scss';

interface IDatePickerProps {
    values?: any;
    onChange?: () => void;
}

export const DatePicker: React.FC<IDatePickerProps> = ({ values, onChange }) => {
    return <ADatePicker.RangePicker format={'DD.MM.YYYY'} value={values} onChange={onChange} />;
};
