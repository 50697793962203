import { host } from 'constants/api';

import axios, { AxiosResponse } from 'axios';
import { Admin } from 'store/reducer/admins';
import { Response } from 'types/api';

interface AdminsResponse extends Response {
    admins: Array<Admin>;
}

export const fetchAdminsRequest = async (): Promise<AdminsResponse> => {
    return axios.get<never, AxiosResponse<AdminsResponse>>(`${host}/admin/admins/all`).then((res) => res.data);
};

interface AddAdminResponse extends Response {
    admin: Admin;
}

export const fetchAddAdminRequest = async ({ name, platformId }: { name: string, platformId:string }): Promise<AddAdminResponse> => {
    return axios
        .post<never, AxiosResponse<AddAdminResponse>>(`${host}/admin/admins/add`, { name, adminStatus: 1, platformId})
        .then((res) => res.data);
};

interface DeleteAdminResponse extends Response {
    admin: Admin;
}

export const fetchDeleteAdminRequest = async (id: number): Promise<DeleteAdminResponse> => {
    return axios
        .post<never, AxiosResponse<AddAdminResponse>>(`${host}/admin/admins/delete`, { adminId: id })
        .then((res) => res.data);
};

interface ChangeAccessResponse extends Response {
    adminRules: Record<string, 1 | 2>;
}


export const fetchChangeAccessRequest = async (payload: {
    adminId: string;
    adminRules: Record<string, 1 | 2>;
}): Promise<ChangeAccessResponse> => {
    return await axios
        .put<never, AxiosResponse<ChangeAccessResponse>>(`${host}/admin/admins/rules/update`, payload)
        .then((res) => res.data);
};

interface DeleteAdminResponse extends Response {
    admin: Admin;
}

export const fetchUpdateTokenAdminRequest = async (id: string): Promise<DeleteAdminResponse> => {
    return axios
        .put<never, AxiosResponse<AddAdminResponse>>(`${host}/admin/admins/update`, {
            adminId: id,
            newToken: true
        })
        .then((res) => res.data);
};
