import React, { useCallback, useState } from 'react';
import { Navigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchAuth } from 'store/action/auth';
import { useAppDispatch, useAppSelector } from 'store/store';

import { Button, Input, Subtitle, Title } from 'components';

import './Auth.scss';

export const CnAuth = cn('auth');

export const Auth: React.FC = () => {
    const dispatch = useAppDispatch();

    const { isLoggedIn } = useAppSelector((store) => store.auth);

    const [token, setToken] = useState('');

    const tokenChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setToken(e.target.value);
    }, []);

    const buttonClickHandler = () => {
        void dispatch(fetchAuth(token));
    };

    if (isLoggedIn) {
        return <Navigate to="/" />;
    }

    return (
        <div className={CnAuth()}>
            <Title>Авторизация</Title>

            <Subtitle>

            </Subtitle>

            <div className={CnAuth('form')}>
                <Input value={token} onChange={tokenChangeCallback} placeholder="Введите токен" />

                <Button onClick={buttonClickHandler} view="primary" size="m">
                    Войти
                </Button>
            </div>
        </div>
    );
};
