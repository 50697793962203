import React from 'react';
import { useNavigate } from 'react-router';
// import { Link } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { Plus } from 'assets';

import { Button, TemplatesTable, Title } from 'components';

import './Templates.scss';

const CnTemplates = cn('templates');

export const Templates: React.FC = () => {
    const navigate = useNavigate();

    const addTemplateHandler = () => {
        navigate('?modal=add-template');
    };

    return (
        <div className={CnTemplates()}>
            <div className={CnTemplates('header')}>
                <Title size="m">Шаблоны</Title>

                <div className={CnTemplates('row')}>
                    {/*<Link to="/templates/groups">*/}
                    {/*    <Button view="primary" size="m">*/}
                    {/*        Группы*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}

                    <Button onClick={addTemplateHandler} view="secondary" size="m">
                        <Plus /> Добавить
                    </Button>
                </div>
            </div>

            <div className={CnTemplates('content')}>
                <TemplatesTable />
            </div>
        </div>
    );
};
