import React from 'react';
import { cn } from '@bem-react/classname';

import './TableItem.scss';

const CnTableItem = cn('tableItem');

export const TableItem: React.FC<{ onClick?: () => void }> = ({ children, onClick }) => {
    return (
        <td onClick={onClick} className={CnTableItem()}>
            {children}
        </td>
    );
};
