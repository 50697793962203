import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchAddTemplateRequest,
    fetchChangeTemplateRequest,
    fetchDeleteTemplateRequest,
    fetchTemplatesRequest,
} from 'api/templates';
import { Sort } from 'types/api';

export const fetchTemplates = createAsyncThunk('@templates/fetch', async (payload: { offset: number, search: string, sort: Sort | null, group: { label: string, value: string } | null } | undefined = undefined) => {
    return await fetchTemplatesRequest(payload);
});

export const fetchAddTemplate = createAsyncThunk('@templates/add', async (data: unknown) => {
    await fetchAddTemplateRequest(data);

    return await fetchTemplatesRequest();
});

export const fetchDeleteTemplate = createAsyncThunk('@templates/delete', async (templateId: string) => {
    await fetchDeleteTemplateRequest(templateId);

    return await fetchTemplatesRequest();
});

export const fetchChangeTemplate = createAsyncThunk(
    '@templates/change',
    async ({ templateId, data }: { templateId: string; data: unknown }) => {
        const template = await fetchChangeTemplateRequest(templateId, data);
        const templates = await fetchTemplatesRequest();

        return {
            template: template.template,
            ...templates
        }
    },
);

export const setTemplatePage = createAction<number>("@template/setPage");

export const setTemplateSearch = createAction<string>("@template/search");


export const setTemplateSort = createAction<Sort>("@template/setSort");

export const setTemplateGroup = createAction<{
    label: string;
    value: string;
}>("@template/setGroup");