import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
// import { useLocation } from 'react-router';
import { cn } from '@bem-react/classname';
import {Trash} from "assets";
import {fetchAddEvent} from "store/action/events";
import {fetchGroups} from "store/action/groups";
import {fetchTemplates} from "store/action/templates";
// import {Plus, Trash} from "assets";
// import { fetchAddEvent } from 'store/action/events';
// import {Condition} from "store/reducer/events";
// import {Group} from "store/reducer/groups";
// import {Template} from "store/reducer/templates";
import {useAppSelector} from 'store/store';

// import {FetchStatus} from "types/api";
import {Button, Input, Select, Title} from 'components';

// import {conditionOptions, conditionOptionsMap} from "./AddEvent.constants";
import './AddEvent.scss';

const CnChangeEvent = cn('addEvent');

export const AddEvent: React.FC = () => {
    const navigate = useNavigate();

    // const { fetchStatus, data } = useAppSelector((store) => store.groups);
    const { data: templatesArr } = useAppSelector((store) => store.templates);
    const [errors, setErrors] = useState<Array<'name' | 'trigger'>>([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTemplates());
        dispatch(fetchGroups({ offset: 0 }));
    }, [dispatch]);

    //--------------------------------- Имя триггер описание ----------------------------
    const [name, setName] = useState('');
    const [trigger, setTrigger] = useState('');
    const [description, setDescription] = useState('');
    const nameChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (errors.includes('name')) {
            setErrors((prev) => prev.filter((err) => err !== 'name'));
        }
        setName(e.target.value);
    }, [errors]);
    const descriptionChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    }, []);
    const triggerChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (errors.includes('trigger')) {
            setErrors((prev) => prev.filter((err) => err !== 'trigger'));
        }
        setTrigger(e.target.value);
    }, [errors]);
    //--------------------------------- Имя триггер описание ----------------------------

    //--------------------------------- Список групп шаблонов ---------------------------
    // const templateGroupOptions = useMemo(
    //     () => (data ? data.map((group) => ({ value: group.id, label: group.name })) : []),
    //     [data],
    // )
    //
    // const [templateGroup, setTemplateGroup] = useState<{ value: string; label: string } | null>();
    // const templateGroupChangeCallback = useCallback(
    //     (obj: unknown) => {
    //         // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //         const value: any = obj;
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    //         setTemplateGroup(value);
    //     },
    //     [],
    // );
    //--------------------------------- Список групп шаблонов ---------------------------


    //--------------------------------- Список шаблонов ---------------------------------
    const [templates, setTemplates] = useState<Array<{ value: string; label: string }>>([]);
    const templateOptions = useMemo(
        () => (templatesArr ? templatesArr.map((template) => ({ value: template.id, label: template.name })) : []),
        [templatesArr],
    );
    const templateChangeCallback = useCallback(
        (obj: unknown) => {
            const newValue = obj as { value: string; label: string };

            if (!templates.find((event) => newValue.value === event.value)) {
                setTemplates((prev) => [...prev, newValue]);
            }
        },
        [templates],
    );
    const deleteTemplateChangeCallback = useCallback(
        (index: number) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const newTemplates = JSON.parse(JSON.stringify(templates));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            newTemplates.splice(index, 1);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setTemplates(newTemplates);
        },
        [templates],
    );
    //--------------------------------- Список шаблонов ---------------------------------


    //--------------------------------- Условия события ---------------------------------

    const [conditions, ] = useState<
        {
            field: string;
            op: string;
            value: string | number;
            exist: boolean;
            conditionId?: string;
        }[]
    >([]);

    // const conditionChangeCallback = useCallback(
    //     (key: string, index: number, value: unknown) => {
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //         const newConditions = JSON.parse(JSON.stringify(conditions));
    //         const newValue = value as { value: string };
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    //         newConditions[index][key] = newValue.value;
    //
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    //         setConditions(newConditions);
    //     },
    //     [conditions],
    // );

    // const conditionDeleteCallback = useCallback(
    //     (index: number) => {
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //         const newConditions = JSON.parse(JSON.stringify(conditions));
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    //         if (newConditions[index].conditionId) {
    //             // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
    //             dispatch(fetchDeleteCondition(newConditions[index].conditionId));
    //         }
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    //         newConditions.splice(index, 1);
    //
    //         // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    //         setConditions(newConditions);
    //     },
    //     [conditions, dispatch],
    // );
    //
    // const conditionAddCallback = useCallback(() => {
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //     const newConditions = JSON.parse(JSON.stringify(conditions));
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    //     newConditions.push({
    //         field: '',
    //         op: '',
    //         value: '',
    //         exist: false,
    //     });
    //
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    //     setConditions(newConditions);
    // }, [conditions]);
    const submitClickHandler = () => {
        const newErrors: Array<'name' | 'trigger'> = [];

        if (!name) {
            newErrors.push('name');
        }

        if (!trigger) {
            newErrors.push('trigger');
        }

        if (!newErrors.length) {
            setTimeout(() => {
                navigate(location.pathname);
            }, 500);
            dispatch(
                fetchAddEvent({
                    name,
                    trigger: trigger,
                    description: description,
                    // templateGroupId: templateGroup,
                    templates: templates.map((template) => template.value),
                    conditions: conditions,
                }),
            );
        } else {
            setErrors(newErrors);
        }
    };
    return (
        <div className={CnChangeEvent()}>
            <div className={CnChangeEvent('header')}>
                <Title size="xs">Добавить событие</Title>
            </div>

            <div className={CnChangeEvent('form', { size: 'medium' })}>
                <Input
                    // isInvalid={errors.includes('name')}
                    value={name}
                    onChange={nameChangeCallback}
                    label="Название события"
                    invalidMessage="Обязательное поле"
                    placeholder=""
                />
            </div>
            <div className={CnChangeEvent('form', { size: 'medium' })}>
                <Input
                    // isInvalid={errors.includes('trigger')}
                    value={trigger}
                    onChange={triggerChangeCallback}
                    label="Триггер события"
                    invalidMessage="Обязательное поле"
                    placeholder=""
                />
            </div>
            <div className={CnChangeEvent('form', { size: 'medium' })}>
                <Input
                    value={description}
                    onChange={descriptionChangeCallback}
                    label="Описание события"
                    placeholder=""
                />
            </div>

            {/*<div className={CnChangeEvent('form', { size: 'medium' })}>*/}
            {/*    <Select*/}
            {/*        isLoading={fetchStatus === FetchStatus.FETCHING}*/}
            {/*        options={[{ value: '', label: 'Отсутвует'},...templateGroupOptions]}*/}
            {/*        label="Группа шаблонов"*/}
            {/*        placeholder="Выберете группу шаблонов"*/}
            {/*        value={templateGroup}*/}
            {/*        onChange={templateGroupChangeCallback}*/}
            {/*        isDisabled={templates != null && templates.length>0}*/}
            {/*    ></Select>*/}
            {/*</div>*/}

            {/*<div className={CnChangeEvent('condition-header')}>*/}
            {/*    <Title size="xxs">ИЛИ</Title>*/}
            {/*</div>*/}

            <div style={{ marginBottom: 40 }} className={CnChangeEvent('condition')}>
                <div className={CnChangeEvent('condition-form')}>
                    <div className={[CnChangeEvent('form', { size: 'medium' }), CnChangeEvent('event-form')].join(' ')}>
                        <Select
                            isLoading={!templatesArr}
                            options={templateOptions}
                            label="Шаблоны"
                            value={null}
                            placeholder="Название шаблона"
                            onChange={templateChangeCallback}
                            // isDisabled={templateGroup!=null && templateGroup.value!=''}
                        ></Select>

                        {templates.map((template, index) => (
                            <div key={template.value} className={CnChangeEvent('event')}>
                                <span>{template.label}</span>

                                <div onClick={() => deleteTemplateChangeCallback(index)} className={CnChangeEvent('trash')}>
                                    <Trash />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/*<div className={CnChangeEvent('form')}>*/}
            {/*    <div className={CnChangeEvent('condition')}>*/}
            {/*        <div className={CnChangeEvent('condition-header')}>*/}
            {/*            <Title size="xxs">Условия</Title>*/}

            {/*            <div onClick={conditionAddCallback} className={CnChangeEvent('addCondition')}>*/}
            {/*                <Plus />*/}
            {/*                Добавить условие*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <Subtitle size="m">Не обязательно. Событие запустится, если соблюдаются все условия</Subtitle>*/}

            {/*        <div className={CnChangeEvent('condition-form')}>*/}
            {/*            {conditions.map(({ value, field, op }, index) => (*/}
            {/*                <div key={index} className={CnChangeEvent('condition-form-row')}>*/}
            {/*                    <Input*/}
            {/*                        value={field}*/}
            {/*                        onChange={(e) => conditionChangeCallback('field', index, { value: e.target.value })}*/}
            {/*                        invalidMessage="Обязательное поле"*/}
            {/*                        placeholder="Поле"*/}
            {/*                    />*/}
            {/*                    <Select*/}
            {/*                        options={conditionOptions}*/}
            {/*                        placeholder="Логический оператор"*/}
            {/*                        value={*/}
            {/*                            op*/}
            {/*                                ? {*/}
            {/*                                    value: op,*/}
            {/*                                    label: conditionOptionsMap[op],*/}
            {/*                                }*/}
            {/*                                : ''*/}
            {/*                        }*/}
            {/*                        onChange={(value) => conditionChangeCallback('op', index, value)}*/}
            {/*                    ></Select>*/}
            {/*                    <Input*/}
            {/*                        value={value}*/}
            {/*                        onChange={(e) => conditionChangeCallback('value', index, { value: e.target.value })}*/}
            {/*                        invalidMessage="Обязательное поле"*/}
            {/*                        placeholder="Значение"*/}
            {/*                    />*/}

            {/*                    <div onClick={() => conditionDeleteCallback(index)} className={CnChangeEvent('trash')}>*/}
            {/*                        <Trash />*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}



            <div className={CnChangeEvent('actions')}>
                <Button onClick={submitClickHandler} view="primary" size="m">
                    Добавить
                </Button>
            </div>
        </div>
    );
};
