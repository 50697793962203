import { host } from "constants/api"

import axios, { AxiosResponse } from "axios"
import { Tag } from "store/reducer/tags";
import { Response } from "types/api";


interface TagsResponse extends Response {
    tags: Tag[];
}

export const fetchTagsRequest = async (): Promise<TagsResponse> => {
    return await axios.get<never, AxiosResponse<TagsResponse>>(`${host}/admin/tag/all`).then(res => res.data)
}