import React from 'react';
import { cn } from '@bem-react/classname';

import './Block.scss';

const CnBlock = cn('block');

export const Block: React.FC = ({ children }) => {
    return <div className={CnBlock()}>{children}</div>;
};
