/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { host } from "constants/api"
import { itemsPerPageCount } from "constants/pagination";

import axios, { AxiosResponse } from "axios"
import { SendedMessage } from "store/reducer/sendedMessages";
import { Sort } from "types/api";

interface SendedMessagesResponse {
    messages: SendedMessage[];
    countItems: number;
}


export const fetchSendedMessagesRequest = async (payload: { tag: string, search: string, offset: number, dateFrom: string, dateTo: string, sort: Sort | null }): Promise<SendedMessagesResponse> => {
    let search = `?limit=${itemsPerPageCount}&offset=${payload.offset}&`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const tag = JSON.parse(JSON.stringify(payload.tag));

    if (payload.tag) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        search += `tagId=${tag.value}&`
    }

    if (payload.search) {
        search += `search=${payload.search}&`
    }

    if (payload.dateFrom) {
        search += `dateFrom=${payload.dateFrom}&`
    }

    if (payload.dateTo) {
        search += `dateTo=${payload.dateTo}&`
    }

    if (payload.sort) {
        search += `sortBy=date.${payload.sort}&`
    }



    return await axios.get<never, AxiosResponse<SendedMessagesResponse>>(`${host}/admin/message/all${search}`).then(res => res.data)
}