import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
// import { fetchGroups } from 'store/action/groups';
import { fetchAddTemplate } from 'store/action/templates';

// import { useAppSelector } from 'store/store';
import { Button, Input, Title } from 'components';

import './AddTemplate.scss';

const CnAddTemplate = cn('addTemplate');

export const AddTemplate: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    // const { data: groups } = useAppSelector((store) => store.groups);

    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    // const [group, setGroup] = useState<{ label: string; value: string } | null>(null);

    const [errors, setErrors] = useState<Array<'name' | 'title' | 'text' | 'group'>>([]);

    // useEffect(() => {
    //     dispatch(fetchGroups({ offset: 0 }));
    // }, [dispatch]);

    // const groupChangeCallback = (value: unknown) => {
    //     const newValue = value as { label: string; value: string };
    //     setGroup(newValue);
    // };

    const nameChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('name')) {
                setErrors((prev) => prev.filter((err) => err !== 'name'));
            }
            setName(e.target.value);
        },
        [errors],
    );

    const titleChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('title')) {
                setErrors((prev) => prev.filter((err) => err !== 'title'));
            }
            setTitle(e.target.value);
        },
        [errors],
    );

    const textChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('text')) {
                setErrors((prev) => prev.filter((err) => err !== 'text'));
            }
            setText(e.target.value);
        },
        [errors],
    );

    const submitClickHandler = () => {
        const newErrors: Array<'name' | 'text' | 'title' | 'group'> = [];

        if (!name) {
            newErrors.push('name');
        }

        // if (!group) {
        //     newErrors.push('group');
        // }

        if (!text) {
            newErrors.push('text');
        }

        if (!title) {
            newErrors.push('title');
        }

        if (!newErrors.length) {
            navigate(location.pathname);
            dispatch(
                fetchAddTemplate({
                    name,
                    title: title,
                    message: text,
                    // templateGroupId: group?.value,
                }),
            );
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className={CnAddTemplate()}>
            <div className={CnAddTemplate('header')}>
                <Title size="xs">Создать шаблон</Title>
                {/* <Subtitle size="m">{state?.name}</Subtitle> */}
            </div>

            <div className={CnAddTemplate('form')}>
                {/*<Select*/}
                {/*    placeholder="Группы"*/}
                {/*    onChange={groupChangeCallback}*/}
                {/*    value={group}*/}
                {/*    label="Группа"*/}
                {/*    options={groups?.map(({ name, id }) => ({*/}
                {/*        label: name,*/}
                {/*        value: id,*/}
                {/*    }))}*/}
                {/*    isLoading={!groups}*/}
                {/*/>*/}

                <Input
                    isInvalid={errors.includes('name')}
                    value={name}
                    onChange={nameChangeCallback}
                    label="Название"
                    invalidMessage="Обязательное поле"
                    placeholder=""
                />

                <Input
                    isInvalid={errors.includes('title')}
                    value={title}
                    onChange={titleChangeCallback}
                    label="Заголовок"
                    placeholder=""
                    invalidMessage="Обязательное поле"
                />

                <Input
                    isInvalid={errors.includes('text')}
                    value={text}
                    onChange={textChangeCallback}
                    as="textarea"
                    label="Текст"
                    invalidMessage="Обязательное поле"
                    placeholder=""
                    rows={5}
                />
            </div>

            <div className={CnAddTemplate('actions')}>
                <Button onClick={submitClickHandler} view="primary" size="m">
                    Создать
                </Button>

                {/* <Button onClick={cancelClickHandler} view="secondary" size="m">
                    Отмена
                </Button> */}
            </div>
        </div>
    );
};
