import { itemsPerPageCount } from 'constants/pagination';

import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { fetchSendedMessages, setSendedMessagesPage } from 'store/action/sendedMessages';
import { useAppSelector } from 'store/store';

import { Block, Pagination, Table, TableHeaderItem } from 'components';

import { SendedMessagesSearch } from './SendedMessagesSearch/SendedMessagesSearch';
import { SendedMessagesTableItem } from './SendedMessagesTableItem/SendedMessagesTableItem';

import './SendedMessagesTable.scss';

const CnSendedMessagesTable = cn('sendedMessagesTable');

export const SendedMessagesTable: React.FC = () => {
    const dispatch = useDispatch();
    const { currentPage, itemsCount, search, sendedMessages } = useAppSelector((store) => store.sendedMessages);
    const pagesCount = useMemo(() => Math.ceil(itemsCount / itemsPerPageCount), [itemsCount]);

    useEffect(() => {
        dispatch(
            fetchSendedMessages({
                offset: (currentPage - 1) * itemsPerPageCount,
                tag: search.tag,
                search: search.search,
                dateFrom: search.dateFrom,
                dateTo: search.dateTo,
                sort: search.sort,
            }),
        );
    }, [currentPage, dispatch, search, pagesCount]);

    const pageClickHandler = (page: number) => {
        dispatch(setSendedMessagesPage(page));
    };

    return (
        <Block>
            {/* <div className={CnMessagesTable('title')}>полученные сообщения</div> */}

            <div className={CnSendedMessagesTable('search')}>
                <SendedMessagesSearch />
            </div>
            <div className={CnSendedMessagesTable('scroll')}>
                <Table className={CnSendedMessagesTable()}>
                    <thead>
                        <tr>
                            <TableHeaderItem>Время</TableHeaderItem>
                            <TableHeaderItem>Получатель</TableHeaderItem>
                            <TableHeaderItem>Сообщение</TableHeaderItem>
                            <TableHeaderItem>Теги</TableHeaderItem>
                        </tr>
                    </thead>

                    <tbody>
                        {sendedMessages &&
                            sendedMessages.map((sendedMessage) => (
                                <SendedMessagesTableItem key={sendedMessage.id} {...sendedMessage} />
                            ))}
                    </tbody>
                </Table>
            </div>

            <Pagination pagesCount={pagesCount} pageClickHandler={pageClickHandler} currentPage={currentPage} />
        </Block>
    );
};
