import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchDeleteEvent } from 'store/action/events';

import { Button, Subtitle, Title } from 'components';

import './DeleteEvent.scss';

const CnDeleteEvent = cn('deleteEvent');

export const DeleteEvent: React.FC = () => {
    const location = useLocation();
    const state = location.state as { name: string; id: string };

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const deleteClickHandler = () => {
        navigate(location.pathname);

        dispatch(fetchDeleteEvent({ eventId: state.id }));
    };

    const cancelClickHandler = () => {
        navigate(location.pathname, { state });
    };

    return (
        <div className={CnDeleteEvent()}>
            <div className={CnDeleteEvent('header')}>
                <Title size="xs">Удалить событие</Title>
                <Subtitle size="m">{state?.name}</Subtitle>
            </div>

            <div className={CnDeleteEvent('actions')}>
                <Button onClick={deleteClickHandler} view="primary" size="m">
                    Удалить
                </Button>

                <Button onClick={cancelClickHandler} view="secondary" size="m">
                    Отмена
                </Button>
            </div>
        </div>
    );
};
