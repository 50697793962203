import React, { useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { fetchVariables } from 'store/action/variables';
import { useAppDispatch, useAppSelector } from 'store/store';

import { Block, Table, TableHeaderItem } from 'components';

import { VariablesTableItem } from './VariablesTableItem/VariablesTableItem';

import './VariablesTable.scss';

const CnVariablesTable = cn('adminsTable');

export const VariablesTable: React.FC = () => {
    const { data } = useAppSelector((store) => store.variables);
    const dispatch = useAppDispatch();

    useEffect(() => {
        void dispatch(fetchVariables());
    }, [dispatch]);

    return (
        <Block>
            <Table className={CnVariablesTable()}>
                <thead>
                    <tr>
                        <TableHeaderItem>Переменная</TableHeaderItem>
                        <TableHeaderItem>Значения</TableHeaderItem>
                        <TableHeaderItem>Действия</TableHeaderItem>
                    </tr>
                </thead>

                <tbody>{data && data.map((variable) => <VariablesTableItem key={variable.id} {...variable} />)}</tbody>
            </Table>
        </Block>
    );
};
