import React from 'react';
import { cn } from '@bem-react/classname';

import './TableHeaderItem.scss';

const CnTableHeaderItem = cn('tableHeaderItem');

export const TableHeaderItem: React.FC = ({ children }) => {
    return <th className={CnTableHeaderItem()}>{children}</th>;
};
