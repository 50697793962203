import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';

import { modalTypes } from './Modal.constants';

import './Modal.scss';

const CnModal = cn('modal');

export const Modal: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { search, pathname, state } = useLocation();
    const navigate = useNavigate();

    const { isModalShow, content }: { isModalShow: boolean; content: JSX.Element | null } = useMemo(() => {
        const params = new URLSearchParams(search);

        const modalType = params.get('modal');

        const content = modalTypes[String(modalType)] ?? null;

        const isModalShow = Boolean(content);

        return {
            isModalShow,
            content,
        };
    }, [search]);

    const closeModal = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        navigate(pathname, { state });
    };

    return (
        <div className={CnModal({ show: isModalShow })}>
            <div className={CnModal('content')}>
                <div onClick={closeModal} className={CnModal('close')}>
                    &times;
                </div>
                {content}
            </div>
        </div>
    );
};
