import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAddVariable, fetchChangeVariable, fetchDeleteVariable, fetchVariables } from 'store/action/variables';
import { FetchStatus } from 'types/api';

export interface Variable {
    id: string;
    name: string;
    value: string;
}

export interface VariablesState {
    fetchStatus: FetchStatus;
    data: Array<Variable> | null;
    error: unknown;
}

const initialState: VariablesState = {
    fetchStatus: FetchStatus.INITIAL,
    data: null,
    error: null,
};

export const variablesSlice = createSlice<VariablesState, SliceCaseReducers<VariablesState>>({
    name: 'variables',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVariables.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchVariables.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.variables;
        });

        builder.addCase(fetchVariables.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });


        builder.addCase(fetchAddVariable.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAddVariable.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.variables;
        });

        builder.addCase(fetchAddVariable.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchChangeVariable.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchChangeVariable.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.variables;
        });

        builder.addCase(fetchChangeVariable.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchDeleteVariable.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchDeleteVariable.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.variables;
        });

        builder.addCase(fetchDeleteVariable.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });
    },
});

export const variablesReducer = variablesSlice.reducer;
