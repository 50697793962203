import { host } from 'constants/api';
import { itemsPerPageCount } from 'constants/pagination';

import axios, { AxiosResponse } from 'axios';
import { Template } from 'store/reducer/templates';
import { Response, Sort } from 'types/api';

interface TemplatesResponse extends Response {
    templates: Array<Template>;
    countItems: number;
}

export const fetchTemplatesRequest = async (payload: { offset: number, search: string, sort: Sort | null, group: { label: string, value: string } | null } | undefined = undefined): Promise<TemplatesResponse> => {
    return axios.get<never, AxiosResponse<TemplatesResponse>>(`${host}/admin/template/all`, {
        params: {
            groupId: payload?.group?.value,
            limit: itemsPerPageCount,
            offset: payload?.offset,
            search: payload?.search,
            sortBy: payload?.sort ? `created_at.${payload?.sort}` : undefined,
        }
    }).then((res) => res.data);
};

interface TemplateResponse extends Response {
    template: Template;
}

export const fetchTemplateRequest = async (payload: string): Promise<TemplateResponse> => {
    return axios.get<never, AxiosResponse<TemplateResponse>>(`${host}/admin/template`, {
        params: {
            templateId: payload
        }
    }).then((res) => res.data);
};

type AddTemplateResponse = Response;

export const fetchAddTemplateRequest = async (data: unknown): Promise<AddTemplateResponse> => {
    return axios
        .post<never, AxiosResponse<AddTemplateResponse>>(`${host}/admin/template`, data)
        .then((res) => res.data);
};

interface ChangeTemplateResponse extends Response {
    template: Template;
}

export const fetchChangeTemplateRequest = async (
    templateId: string,
    template: unknown,
): Promise<ChangeTemplateResponse> => {
    return axios
        .put<never, AxiosResponse<ChangeTemplateResponse>>(`${host}/admin/template`, {
            templateId,
            template,
        })
        .then((res) => res.data);
};

type DeleteTemplateResponse = Response;

export const fetchDeleteTemplateRequest = async (templateId: string): Promise<DeleteTemplateResponse> => {
    return axios
        .post<never, AxiosResponse<DeleteTemplateResponse>>(`${host}/admin/template/delete`, {
            templateId
        })
        .then((res) => res.data);
};

interface TemplatesHistoryResponse extends Response {
    templateHistorys: Template[];
    countItems: number;
}

export const fetchTemplatesHistoryRequest = async (templateId: string, offset: number): Promise<TemplatesHistoryResponse> => {
    return axios
        .get<never, AxiosResponse<TemplatesHistoryResponse>>(`${host}/admin/template/historys?templateId=${templateId}&limit=${itemsPerPageCount}&offset=${offset}`)
        .then((res) => res.data);
};

interface RestoreTemplateResponse extends Response {
    template: Template;
}

export const fetchRestoreTemplateRequest = async (templateHistoryId: string): Promise<RestoreTemplateResponse> => {
    return axios
        .post<never, AxiosResponse<RestoreTemplateResponse>>(`${host}/admin/template/history/restore`, {
            templateHistoryId
        })
        .then((res) => res.data);
};