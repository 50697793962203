import { itemsPerPageCount } from 'constants/pagination';

import React, { useEffect, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { fetchTemplatesHistory, setTemplatesHistoryPage } from 'store/action/templatesHistory';
import { Template } from 'store/reducer/templates';
import { useAppDispatch, useAppSelector } from 'store/store';

import { Block, Pagination, Table, TableHeaderItem } from 'components';

import { TemplatesHistoryTableItem } from './TemplatesHistoryTableItem/TemplatesHistoryTableItem';

import './TemplatesHistoryTable.scss';

const CnTemplatesHistoryTable = cn('templatesHistoryTable');

export const TemplatesHistoryTable: React.FC<{ myTemplate?: Template }> = ({ myTemplate }) => {
    const { data, currentPage, itemsCount, template: sTemplate } = useAppSelector((store) => store.templatesHistory);
    const dispatch = useAppDispatch();
    const pagesCount = useMemo(() => Math.ceil(itemsCount / itemsPerPageCount), [itemsCount]);
    const offset = (currentPage - 1) * itemsPerPageCount;

    useEffect(() => {
        if (sTemplate) {
            void dispatch(
                fetchTemplatesHistory({
                    offset,
                    id: sTemplate.id,
                }),
            );
        }
    }, [dispatch, currentPage, sTemplate, offset]);

    const pageClickHandler = (page: number) => {
        dispatch(setTemplatesHistoryPage(page));
    };

    if (!sTemplate) {
        return null;
    }

    return (
        <Block>
            <div className={CnTemplatesHistoryTable('title')}>история версий</div>
            <div className={CnTemplatesHistoryTable('scroll')}>
                <Table className={CnTemplatesHistoryTable()}>
                    <thead>
                        <tr>
                            <TableHeaderItem>Время</TableHeaderItem>
                            <TableHeaderItem>Заголовок</TableHeaderItem>
                            <TableHeaderItem>Текст</TableHeaderItem>
                            <TableHeaderItem>Действия</TableHeaderItem>
                        </tr>
                    </thead>

                    <tbody>
                        {data &&
                            data.map((template) => (
                                <TemplatesHistoryTableItem
                                    currentTemplateId={myTemplate?.id ?? ''}
                                    offset={offset}
                                    key={template.id}
                                    {...template}
                                />
                            ))}
                    </tbody>
                </Table>
            </div>

            <Pagination pagesCount={pagesCount} pageClickHandler={pageClickHandler} currentPage={currentPage} />
        </Block>
    );
};
