import React from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Plus } from 'assets';

import { Button, EventsTable, Title } from 'components';

import './Events.scss';

const CnEvents = cn('events');

export const Events: React.FC = () => {
    const navigate = useNavigate();

    const addGroupHandler = () => {
        navigate('?modal=add-event');
    };

    return (
        <div className={CnEvents()}>
            <div className={CnEvents('header')}>
                <Title size="m">События</Title>

                <Button onClick={addGroupHandler} view="secondary" size="m">
                    <Plus /> Добавить
                </Button>
            </div>

            <div className={CnEvents('content')}>
                <EventsTable />
            </div>
        </div>
    );
};
