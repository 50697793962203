import React from 'react';
import { cn } from '@bem-react/classname';

import './Button.scss';

const CnButton = cn('button');

interface IButtonProps {
    size: 'm' | 'l' | 's';
    view: 'primary' | 'secondary' | 'blue';
    onClick?: () => void;
    disabled?: boolean;
}

export const Button: React.FC<IButtonProps> = ({ children, size, view, ...other }) => {
    return (
        <button className={CnButton({ size, view })} {...other}>
            {children}
        </button>
    );
};
