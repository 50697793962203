import { itemsPerPageCount } from 'constants/pagination';

import React, { useCallback, useEffect, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { fetchGroups, setGroupsCurrentPage, setGroupsSearch } from 'store/action/groups';
import { useAppDispatch, useAppSelector } from 'store/store';

import { Block, Input, Pagination, Table, TableHeaderItem } from 'components';

import { GroupsTableItem } from './GroupsTableItem/GroupsTableItem';

import './GroupsTable.scss';

const CnGroupsTable = cn('groupsTable');

export const GroupsTable: React.FC = () => {
    const { data, currentPage, itemsCount, search } = useAppSelector((store) => store.groups);
    const dispatch = useAppDispatch();
    const pagesCount = useMemo(() => Math.ceil(itemsCount / itemsPerPageCount), [itemsCount]);

    useEffect(() => {
        void dispatch(
            fetchGroups({
                offset: (currentPage - 1) * itemsPerPageCount,
                limit: itemsPerPageCount,
                search,
            }),
        );
    }, [dispatch, currentPage, search]);

    const pageClickHandler = (page: number) => {
        dispatch(setGroupsCurrentPage(page));
    };

    const searchChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setGroupsSearch(e.target.value));
        },
        [dispatch],
    );

    return (
        <Block>
            <div className={CnGroupsTable('search')}>
                <Input value={search} onChange={searchChangeCallback} placeholder="Искать" />
            </div>

            <div className={CnGroupsTable('scroll')}>
                <Table className={CnGroupsTable()}>
                    <thead>
                        <tr>
                            <TableHeaderItem>Имя</TableHeaderItem>
                            <TableHeaderItem>Действия</TableHeaderItem>
                        </tr>
                    </thead>

                    <tbody>{data && data.map((template) => <GroupsTableItem key={template.id} {...template} />)}</tbody>
                </Table>
            </div>

            <Pagination pagesCount={pagesCount} pageClickHandler={pageClickHandler} currentPage={currentPage} />
        </Block>
    );
};
