import { itemsPerPageCount } from 'constants/pagination';

import React, { useCallback, useEffect, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { fetchEvents, setEventsCurrentPage, setEventsSearch } from 'store/action/events';
import { useAppDispatch, useAppSelector } from 'store/store';

import { Block, Input, Pagination, Table, TableHeaderItem } from 'components';

import { EventsTableItem } from './EventsTableItem/EventsTableItem';

import './EventsTable.scss';

const CnEventsTable = cn('eventsTable');

export const EventsTable: React.FC = () => {
    const { data, currentPage, itemsCount, search } = useAppSelector((store) => store.events);
    const dispatch = useAppDispatch();
    const pagesCount = useMemo(() => Math.ceil(itemsCount / itemsPerPageCount), [itemsCount]);

    useEffect(() => {
        void dispatch(
            fetchEvents({
                offset: (currentPage - 1) * itemsPerPageCount,
                limit: itemsPerPageCount,
                search,
            }),
        );
    }, [dispatch, currentPage, search]);

    const pageClickHandler = (page: number) => {
        dispatch(setEventsCurrentPage(page));
    };

    const searchChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setEventsSearch(e.target.value));
        },
        [dispatch],
    );

    return (
        <Block>
            <div className={CnEventsTable('search')}>
                <Input value={search} onChange={searchChangeCallback} placeholder="Искать" />
            </div>

            <div className={CnEventsTable('scroll')}>
                <Table className={CnEventsTable()}>
                    <thead>
                        <tr>
                            <TableHeaderItem>Имя</TableHeaderItem>
                            <TableHeaderItem>Триггер</TableHeaderItem>
                            <TableHeaderItem>Описание</TableHeaderItem>
                            <TableHeaderItem>Кол-во шаблонов</TableHeaderItem>
                            {/*<TableHeaderItem>Группа шаблонов</TableHeaderItem>*/}
                            <TableHeaderItem>Действия</TableHeaderItem>
                        </tr>
                    </thead>

                    <tbody>{data && data.map((event) => <EventsTableItem key={event.id} {...event} />)}</tbody>
                </Table>
            </div>

            <Pagination pagesCount={pagesCount} pageClickHandler={pageClickHandler} currentPage={currentPage} />
        </Block>
    );
};
