import React from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Plus } from 'assets';

import { Button, Title, VariablesTable } from 'components';

import './Variables.scss';

const CnVariables = cn('variables');

export const Variables: React.FC = () => {
    const navigate = useNavigate();

    const addVariableHandler = () => {
        navigate('?modal=add-variable');
    };

    return (
        <div className={CnVariables()}>
            <div className={CnVariables('header')}>
                <Title size="m">Переменные</Title>

                <Button onClick={addVariableHandler} view="secondary" size="m">
                    <Plus /> Создать
                </Button>
            </div>

            <div className={CnVariables('content')}>
                <VariablesTable />
            </div>
        </div>
    );
};
