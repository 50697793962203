import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchLogsRequest } from "api/logs";
import { Sort } from "types/api";


export const fetchLogs = createAsyncThunk("@logs/fetch", async (payload: { id: string, offset: number, from: string, to: string, sort: Sort | null }) => {
    return await fetchLogsRequest(payload);
})


export const setLogsSort = createAction<Sort>("@logs/setSort");

export const setLogsCurrentPage = createAction<number>("@logs/setCurrentPage");