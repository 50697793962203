import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Receiver } from 'store/reducer/receivers';

import { MessagesTable, Title } from 'components';

import './Messages.scss';

const CnMessages = cn('messages');

export const Messages: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const state = location.state as Receiver;

    return (
        <div className={CnMessages()}>
            <div className={CnMessages('header')}>
                <div onClick={() => navigate('/receivers')} className={CnMessages('back')}>
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.8415 8.825L3.02484 5L6.8415 1.175L5.6665 0L0.666504 5L5.6665 10L6.8415 8.825Z"
                            fill="#969595"
                        />
                    </svg>
                    Получатели
                </div>
                <Title size="m">{`ID ${state.externalUserId}`}</Title>
            </div>

            <div className={CnMessages('content')}>
                <MessagesTable />
            </div>
        </div>
    );
};
