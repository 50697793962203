import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction, CombinedState, combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';

import { adminsReducer as admins } from './reducer/admins';
import { authReducer as auth } from './reducer/auth';
import { eventsReducer as events } from './reducer/events';
import { groupsReducer as groups } from './reducer/groups';
import { logsReducer as logs } from './reducer/logs';
import { messagesReducer as messages } from './reducer/messages';
import { platformsReducer as platforms } from './reducer/platforms';
import { receiversReducer as receivers } from './reducer/receivers';
import { sendedMessagesReducer as sendedMessages } from './reducer/sendedMessages';
import { tagsReducer as tags } from './reducer/tags';
import { templatesReducer as templates } from './reducer/templates';
import { templatesHistoryReducer as templatesHistory } from './reducer/templatesHistory';
import { unsubscribesReducer as unsubscribes } from './reducer/unsubscribes';
import { variablesReducer as variables } from './reducer/variables';

const reducer = combineReducers({
    admins,
    auth,
    templates,
    platforms,
    receivers,
    messages,
    variables,
    logs,
    tags,
    sendedMessages,
    unsubscribes,
    templatesHistory,
    groups,
    events
});

export const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [],
});

export type State = ReturnType<typeof store.getState>;
export type Action = typeof store.dispatch;

export const useAppDispatch = (): ThunkDispatch<CombinedState<State>, null, AnyAction> => useDispatch<Action>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
