import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchChangeAccess } from 'store/action/admins';

import { Button, Select, Subtitle, Title } from 'components';

import { accessOptions, labelFromAccessOption, labelFromField } from './ChangeAccess.constants';

import './ChangeAccess.scss';

const CnChangeAccess = cn('changeAccess');

export const ChangeAccess: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = location.state as {
        id: string;
        name: string;
        token: string;
        rulesId: string;
        rules: Record<string, 1 | 2>;
    };

    const [rules, setRules] = useState<Record<string, 1 | 2>>(state.rules);

    const rulesChangeCallback = useCallback(
        (key: string, value: unknown) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const newRules = JSON.parse(JSON.stringify(rules));
            const parsedValue = value as { value: 1 | 2 };

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            newRules[key] = parsedValue.value;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setRules(newRules);
        },
        [rules],
    );

    const submitClickHandler = () => {
        navigate(location.pathname);
        dispatch(
            fetchChangeAccess({
                adminId: state.id,
                adminRules: rules,
            }),
        );
    };

    const renderRules = useMemo(() => {
        return Object.keys(rules).map((key) => {
            const value = rules[key];
            const label = labelFromField[key];

            return (
                <div className={CnChangeAccess('field')} key={key}>
                    <div className={CnChangeAccess('label')}>{label}</div>
                    <div className={CnChangeAccess('select')}>
                        <Select
                            options={accessOptions}
                            value={
                                value
                                    ? {
                                          value: value,
                                          label: labelFromAccessOption[value],
                                      }
                                    : ''
                            }
                            onChange={(value) => rulesChangeCallback(key, value)}
                        ></Select>
                    </div>
                </div>
            );
        });
    }, [rules, rulesChangeCallback]);

    return (
        <div className={CnChangeAccess()}>
            <div className={CnChangeAccess('header')}>
                <Title size="xs">Настроить доступы</Title>

                <Subtitle size="m">для {state.name}</Subtitle>
            </div>

            <div className={CnChangeAccess('form')}>
                <div className={CnChangeAccess('field')}>
                    <div className={`${CnChangeAccess('form-title', { first: true })}`}>Страница</div>
                    <div className={`${CnChangeAccess('form-title', { second: true })}`}>Уровень доступа</div>
                </div>
                {renderRules}
            </div>

            <div className={CnChangeAccess('actions')}>
                <Button onClick={submitClickHandler} view="primary" size="m">
                    Сохранить
                </Button>
            </div>
        </div>
    );
};
