import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAddPlatformRequest, fetchDeletePlatformRequest, fetchPlatformsRequest } from 'api/platforms';

export const fetchPlatforms = createAsyncThunk('@platforms/fetch', async () => {
    return await fetchPlatformsRequest();
});

export const fetchAddPlatform = createAsyncThunk('@platforms/add', async (name: string) => {
    await fetchAddPlatformRequest(name);

    return await fetchPlatformsRequest();
});


export const fetchDeletePlatform = createAsyncThunk('@platforms/delete', async (platformId: string) => {
    await fetchDeletePlatformRequest(platformId);

    return await fetchPlatformsRequest();
});