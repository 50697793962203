import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchAddAdmin } from 'store/action/admins';
import {fetchPlatforms} from "store/action/platforms";
import { useAppDispatch,useAppSelector } from 'store/store';

import {Button, Input, Select, Title} from 'components';

import './AddAdmin.scss';

const CnAddAdmin = cn('addAdmin');

export const AddAdmin: React.FC = () => {
    const [name, setName] = useState('');
    const [platform, setPlatform] = useState<{ label: string; value: string }>({label: "", value: ""});
    const dispatch = useAppDispatch();
    const dispatchPlatform = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { platforms: platforms } = useAppSelector((store) => store.platforms);


    useEffect(() => {
        dispatchPlatform(fetchPlatforms());
    }, [dispatchPlatform]);

    const platformChangeCallback = (value: unknown) => {
        const newValue = value as { label: string; value: string };
        setPlatform(newValue);
    };

    const nameChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    const buttonClickHandler = () => {
        navigate(pathname);
        void dispatch(fetchAddAdmin({ name:name, platformId: platform.value }));
    };
    return (
        <div className={CnAddAdmin()}>
            <div className={CnAddAdmin('header')}>
                <Title size="xs">Добавить администратора</Title>
            </div>
            <div className={CnAddAdmin('input')}>
                <Input value={name} onChange={nameChangeCallback} label="ФИО" placeholder="Введите имя" />
                <p></p>
                <Select
                    placeholder="Платформы"
                    onChange={platformChangeCallback}
                    value={platform}
                    label="Платформа"
                    options={platforms?.map(({ name, id }) => ({
                        label: name,
                        value: id,
                    }))}
                    isLoading={!platforms}
                />
            </div>

            <div className={CnAddAdmin('action')}>
                <Button onClick={buttonClickHandler} view="primary" size="m">
                    Добавить
                </Button>
            </div>
        </div>
    );
};
