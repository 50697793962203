import React from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Delete, Pen } from 'assets';
import { Group } from 'store/reducer/groups';

import { TableItem } from 'components';

import './GroupsTableItem.scss';

const CnGroupsTableItem = cn('groupsTableItem');

type IGroupsTableItemProps = Group;

export const GroupsTableItem: React.FC<IGroupsTableItemProps> = ({ id, name }) => {
    const navigate = useNavigate();

    const deleteGroupHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        navigate('?modal=delete-group', {
            state: {
                id,
                name,
            },
        });
    };

    const changeGroupHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        navigate('?modal=change-group', {
            state: {
                id,
                name,
            },
        });
    };

    return (
        <tr>
            <TableItem>{name}</TableItem>
            <TableItem>
                <div className={CnGroupsTableItem('actions')}>
                    <div onClick={changeGroupHandler} className={CnGroupsTableItem('action')}>
                        <Pen className={CnGroupsTableItem('icon')} />
                        Изменить
                    </div>

                    <div className={CnGroupsTableItem('action')} onClick={deleteGroupHandler}>
                        <Delete className={CnGroupsTableItem('icon')} />
                        Удалить
                    </div>
                </div>
            </TableItem>
        </tr>
    );
};
