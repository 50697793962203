import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAddUnsubscribe, fetchDeleteUnsubscribe, fetchUnsubscribes } from 'store/action/unsubscribes';
import { FetchStatus } from 'types/api';

export interface Unsubscribe {
    id: string;
    unsubscribe: string;
}

export interface UnsubscribesState {
    fetchStatus: FetchStatus;
    data: Array<Unsubscribe> | null;
    error: unknown;
}

const initialState: UnsubscribesState = {
    fetchStatus: FetchStatus.INITIAL,
    data: null,
    error: null,
};

export const unsubscribesSlice = createSlice<UnsubscribesState, SliceCaseReducers<UnsubscribesState>>({
    name: 'unsubscribes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUnsubscribes.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchUnsubscribes.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.unsubscribes;
        });

        builder.addCase(fetchUnsubscribes.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchAddUnsubscribe.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAddUnsubscribe.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.unsubscribes;
        });

        builder.addCase(fetchAddUnsubscribe.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchDeleteUnsubscribe.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchDeleteUnsubscribe.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.unsubscribes;
        });

        builder.addCase(fetchDeleteUnsubscribe.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });
    },
});

export const unsubscribesReducer = unsubscribesSlice.reducer;
