import { host } from "constants/api"

import axios, { AxiosResponse } from "axios"
import { Event } from 'store/reducer/events';
import { Response } from "types/api";


interface FetchEventsResponse extends Response {
    events: Event[];
    countItems: number;
}

export const fetchEventsRequest = async (payload: { offset: number, limit: number, search?: string }): Promise<FetchEventsResponse> => {
    return await axios.get<never, AxiosResponse<FetchEventsResponse>>(`${host}/admin/event/all`, {
        params: {
            limit: payload.limit,
            offset: payload.offset,
            search: payload.search
        }
    }).then(res => res.data)
}

interface FetchAddEventResponse extends Response {
    event: Event;
}

export const fetchAddEventRequest = async (data: {
    name: string;
    trigger: string;
    templateGroupId: string;
    templates: string[];
    description: string;
    conditions: {
        field: string;
        op: string;
        value: string | number;
    }[];
}): Promise<FetchAddEventResponse> => {
    const event = await axios
        .post<never, AxiosResponse<FetchAddEventResponse>>(`${host}/admin/event`, data)
        .then((res) => res.data);

    return event;
};
interface FetchChangeEventResponse extends Response {
    event: Event;
}

export const fetchChangeEventRequest = async (data: {
    eventId: string;
    name: string;
    trigger: string;
    templateGroupId: string;
    templates: string[];
    description: string;
    conditions: {
        field: string;
        op: string;
        value: string | number;
    }[];
}): Promise<FetchChangeEventResponse> => {
    const event = await axios
        .put<never, AxiosResponse<FetchChangeEventResponse>>(`${host}/admin/event`, data)
        .then((res) => res.data);

    return event;
};

export const fetchDeleteEventRequest = async (payload: { eventId: string }): Promise<Response> => {
    return await axios.post<never, AxiosResponse<Response>>(`${host}/admin/event/delete`, payload).then(res => res.data)
}
