import { itemsPerPageCount } from 'constants/pagination';

import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchMessages, setMessagesPage } from 'store/action/messages';
import { Receiver } from 'store/reducer/receivers';
import { useAppSelector } from 'store/store';

import { Block, Pagination, Table, TableHeaderItem } from 'components';

import { MessagesSearch } from './MessagesSearch/MessagesSearch';
import { MessagesTableItem } from './MessagesTableItem/MessagesTableItem';

import './MessagesTable.scss';

const CnMessagesTable = cn('messagesTable');

export const MessagesTable: React.FC = () => {
    const { messages, itemsCount, currentPage, search } = useAppSelector((store) => store.messages);
    const pagesCount = useMemo(() => Math.ceil(itemsCount / itemsPerPageCount), [itemsCount]);
    const dispatch = useDispatch();
    const location = useLocation();

    const state = location.state as Receiver;

    useEffect(() => {
        setTimeout(() => {
            void dispatch(
                fetchMessages({
                    userId: state.id,
                    search: search,
                    offset: (currentPage - 1) * itemsPerPageCount,
                    dateFrom: search.dateFrom,
                    dateTo: search.dateTo,
                    sort: search.sort,
                }),
            );
        }, 0);
    }, [dispatch, state, search, currentPage]);

    const pageClickHandler = (page: number) => {
        dispatch(setMessagesPage(page));
    };

    return (
        <Block>
            <div className={CnMessagesTable('title')}>полученные сообщения</div>

            <div className={CnMessagesTable('search')}>
                <MessagesSearch />
            </div>
            <div className={CnMessagesTable('scroll')}>
                <Table className={CnMessagesTable()}>
                    <thead>
                        <tr>
                            <TableHeaderItem>Время</TableHeaderItem>
                            <TableHeaderItem>Сообщение</TableHeaderItem>
                            <TableHeaderItem>Прочитано</TableHeaderItem>
                            <TableHeaderItem>Теги</TableHeaderItem>
                        </tr>
                    </thead>

                    <tbody>
                        {messages && messages.map((messages) => <MessagesTableItem key={messages.id} {...messages} />)}
                    </tbody>
                </Table>
            </div>

            <Pagination pagesCount={pagesCount} pageClickHandler={pageClickHandler} currentPage={currentPage} />
        </Block>
    );
};
