import { host } from 'constants/api';

import axios, { AxiosResponse } from 'axios';
import { Platform } from 'store/reducer/platforms';
import { Response } from 'types/api';

interface PlatformsResponse extends Response {
    platforms: Platform[];
}

export const fetchPlatformsRequest = async (): Promise<PlatformsResponse> => {
    return await axios
        .get<never, AxiosResponse<PlatformsResponse>>(`${host}/admin/platform/all`)
        .then((res) => res.data);
};

interface PlatformResponse extends Response {
    platform: Platform;
}

export const fetchAddPlatformRequest = async (name: string): Promise<PlatformResponse> => {
    return await axios
        .post<never, AxiosResponse<PlatformResponse>>(`${host}/admin/platform`, { name })
        .then((res) => res.data);
};


interface PlatformDeleteResponse extends Response {
    platform: Platform;
}

export const fetchDeletePlatformRequest = async (platformId: string): Promise<PlatformDeleteResponse> => {
    return await axios
        .post<never, AxiosResponse<PlatformDeleteResponse>>(`${host}/admin/platform/delete`, { platformId })
        .then((res) => res.data);
};
