import React from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Plus } from 'assets';

import { Button, PlatformsTable, Title } from 'components';

import './Platforms.scss';

const CnPlatforms = cn('platforms');

export const Platforms: React.FC = () => {
    const navigate = useNavigate();

    const addPlatformHandler = () => {
        navigate('?modal=add-platform');
    };

    return (
        <div className={CnPlatforms()}>
            <div className={CnPlatforms('header')}>
                <Title size="m">Платформы</Title>

                <Button onClick={addPlatformHandler} view="secondary" size="m">
                    <Plus /> Добавить
                </Button>
            </div>

            <div className={CnPlatforms('content')}>
                <PlatformsTable />
            </div>
        </div>
    );
};
