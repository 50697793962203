import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchAddPlatform } from 'store/action/platforms';

import { Button, Input, Title } from 'components';

import './AddPlatform.scss';

const CnAddPlatform = cn('addTemplate');

export const AddPlatform: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [name, setName] = useState('');

    const [errors, setErrors] = useState<Array<'name'>>([]);

    const nameChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('name')) {
                setErrors((prev) => prev.filter((err) => err !== 'name'));
            }
            setName(e.target.value);
        },
        [errors],
    );

    const submitClickHandler = () => {
        const newErrors: Array<'name'> = [];

        if (!name) {
            newErrors.push('name');
        }

        if (!newErrors.length) {
            navigate(location.pathname);
            dispatch(fetchAddPlatform(name));
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className={CnAddPlatform()}>
            <div className={CnAddPlatform('header')}>
                <Title size="xs">Добавить платформу</Title>
            </div>

            <div className={CnAddPlatform('form')}>
                <Input
                    isInvalid={errors.includes('name')}
                    value={name}
                    onChange={nameChangeCallback}
                    label="Имя платформы"
                    invalidMessage="Обязательное поле"
                    placeholder=""
                />
            </div>

            <div className={CnAddPlatform('actions')}>
                <Button onClick={submitClickHandler} view="primary" size="m">
                    Создать
                </Button>
            </div>
        </div>
    );
};
