import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSendedMessagesRequest } from "api/sendedMessages";
import { Sort } from "types/api";


export const fetchSendedMessages = createAsyncThunk("@sendedMessages/fetch", async (payload: { tag: string, search: string, offset: number, dateFrom: string, dateTo: string, sort: Sort | null }) => {
    return await fetchSendedMessagesRequest(payload)
})


export const setSendedMessagesPage = createAction<number>("@sendedMessages/setPage");
export const setSendedMessagesSearch = createAction<{ key: string, value: unknown }>("@sendedMessages/setSearch");