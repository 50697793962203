import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMessagesRequest } from 'api/messages';
import { Sort } from 'types/api';

export const fetchMessages = createAsyncThunk('@messages/fetch', async (payload: { userId: string, search: unknown, offset: number, dateFrom: string, dateTo: string, sort: Sort | null }) => {
    return await fetchMessagesRequest(payload);
});


export const setMessagesPage = createAction<number>("@messages/setPage");

export const setMessagesSearch = createAction<{ key: string, value: unknown }>("@messages/search");