import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchChangeVariable } from 'store/action/variables';

import { Button, Input, Title } from 'components';

import './ChangeVariable.scss';

const CnAddTemplate = cn('changeVariable');

export const ChangeVariable: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const state = location.state as {
        name: string;
        id: string;
        value: string;
    };

    const dispatch = useDispatch();

    const [name, setName] = useState(state.name);
    const [value, setValue] = useState(state.value);

    const [errors, setErrors] = useState<Array<'name' | 'value'>>([]);

    const nameChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('name')) {
                setErrors((prev) => prev.filter((err) => err !== 'name'));
            }
            setName(e.target.value);
        },
        [errors],
    );

    const valueChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('value')) {
                setErrors((prev) => prev.filter((err) => err !== 'value'));
            }
            setValue(e.target.value);
        },
        [errors],
    );

    const submitClickHandler = () => {
        const newErrors: Array<'name' | 'value'> = [];

        if (!name) {
            newErrors.push('name');
        }

        if (!value) {
            newErrors.push('value');
        }

        if (!newErrors.length) {
            navigate(location.pathname);
            dispatch(
                fetchChangeVariable({
                    name,
                    value,
                    variableId: state.id,
                }),
            );
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className={CnAddTemplate()}>
            <div className={CnAddTemplate('header')}>
                <Title size="xs">Изменить переменную</Title>
            </div>

            <div className={CnAddTemplate('form')}>
                <Input
                    isInvalid={errors.includes('name')}
                    value={name}
                    onChange={nameChangeCallback}
                    label="Название"
                    invalidMessage="Обязательное поле"
                    placeholder=""
                />

                <Input
                    isInvalid={errors.includes('value')}
                    value={value}
                    onChange={valueChangeCallback}
                    label="Значение"
                    placeholder=""
                    invalidMessage="Обязательное поле"
                />
            </div>

            <div className={CnAddTemplate('actions')}>
                <Button onClick={submitClickHandler} view="primary" size="m">
                    Создать
                </Button>
            </div>
        </div>
    );
};
