import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAddUnsubscribeRequest, fetchDeleteUnsubscribeRequest, fetchUnsubscribesRequest } from "api/unsubscribes";

export const fetchUnsubscribes = createAsyncThunk("@unsubscribes/fetch", async (userId: string) => {
    return await fetchUnsubscribesRequest(userId);
})

export const fetchAddUnsubscribe = createAsyncThunk("@unsubscribe/add", async (data: { userId: string, unsubscribe: string }) => {
    await fetchAddUnsubscribeRequest(data)

    return await fetchUnsubscribesRequest(data.userId);
})


export const fetchDeleteUnsubscribe = createAsyncThunk("@unsubscribe/delete", async (data: { userId: string, unsubscribeId: string }) => {
    await fetchDeleteUnsubscribeRequest(data)

    return await fetchUnsubscribesRequest(data.userId);

})