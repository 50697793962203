import React from 'react';
import { cn } from '@bem-react/classname';

import './Table.scss';

const CnTable = cn('table');

interface ITableProps {
    className?: string;
}

export const Table: React.FC<ITableProps> = ({ children, className }) => {
    return <table className={`${CnTable()} ${String(className)}`}>{children}</table>;
};
