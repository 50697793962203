import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAddAdminRequest, fetchAdminsRequest, fetchChangeAccessRequest, fetchDeleteAdminRequest, fetchUpdateTokenAdminRequest } from 'api/admin';

export const fetchAdmins = createAsyncThunk('@admins/fetch', async () => {
    return await fetchAdminsRequest();
});

export const fetchAddAdmin = createAsyncThunk('@admins/add', async (payload: { name: string,platformId:string }) => {
    await fetchAddAdminRequest(payload);

    return await fetchAdminsRequest();
});

export const fetchDeleteAdmin = createAsyncThunk('@admins/delete', async (payload: number) => {
    await fetchDeleteAdminRequest(payload);

    return await fetchAdminsRequest();
});

export const fetchChangeAccess = createAsyncThunk('@admins/changeAccess', async (payload: {
    adminId: string;
    adminRules: Record<string, 1 | 2>;
}) => {
    await fetchChangeAccessRequest(payload);

    return await fetchAdminsRequest();
});


export const fetchUpdateTokenAdmin = createAsyncThunk("@admins/updateToken", async (payload: string) => {
    await fetchUpdateTokenAdminRequest(payload);

    return await fetchAdminsRequest();
})
