import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAddEvent, fetchChangeEvent, fetchDeleteEvent, fetchEvents, setEventsCurrentPage, setEventsSearch } from 'store/action/events';
import { FetchStatus } from 'types/api';

import {Group} from "./groups";
import {Template} from "./templates";

export interface Event {
    id: string;
    name: string;
    trigger: string;
    description: string;
    conditions: Condition[];
    templateGroup: Group | null;
    templates: Template[] | null;

}
export interface Condition {
    field: string;
    id: string;
    mailingId: string;
    op: string;
    value: string;
}
export interface EventState {
    fetchStatus: FetchStatus;
    error: unknown;
    data: Array<Event> | null;
    itemsCount: number;
    currentPage: number;
    search: string;
}

const initialState: EventState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    data: null,
    itemsCount: 0,
    currentPage: 1,
    search: ''
};

export const eventsSlice = createSlice<EventState, SliceCaseReducers<EventState>>({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEvents.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchEvents.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.events;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchEvents.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchAddEvent.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAddEvent.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.events;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchChangeEvent.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchChangeEvent.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchChangeEvent.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.events;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchDeleteEvent.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchDeleteEvent.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchDeleteEvent.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.events;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchAddEvent.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(setEventsCurrentPage, (state, { payload }) => {
            state.currentPage = payload;
        });

        builder.addCase(setEventsSearch, (state, { payload }) => {
            state.currentPage = 1;

            state.search = payload;
        });
    },
});

export const eventsReducer = eventsSlice.reducer;
