/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchMessages, setMessagesPage, setMessagesSearch } from 'store/action/messages';
import { FetchStatus, Sort } from 'types/api';

import { Tag } from './tags';

export interface Message {
    id: string;
    message: string;
    title: string;
    read: boolean;
    date: string;
    tags: { id: string, name: string }[];
}

export interface MessagesState {
    fetchStatus: FetchStatus;
    error: unknown;
    messages: Array<Message> | null;
    itemsCount: number;
    currentPage: number;
    search: {
        tag: Tag | null;
        text: string | null;
        dateFrom: string;
        dateTo: string;
        sort: Sort | null;
    };
}

const initialState: MessagesState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    messages: null,
    itemsCount: 0,
    currentPage: 1,
    search: {
        tag: null,
        text: null,
        dateFrom: '',
        dateTo: '',
        sort: null,
    },
};

export const messagesSlice = createSlice<MessagesState, SliceCaseReducers<MessagesState>>({
    name: 'messages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMessages.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchMessages.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.messages = payload.messages;

            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchMessages.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(setMessagesPage, (state, { payload }) => {
            state.currentPage = payload;
        });

        builder.addCase(setMessagesSearch, (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const anyPayload: any = payload;
            const newSearch = JSON.parse(JSON.stringify(state.search));

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            newSearch[anyPayload.key] = anyPayload.value;

            state.search = newSearch;
        });
    },
});

export const messagesReducer = messagesSlice.reducer;
