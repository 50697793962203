import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchLogs, setLogsCurrentPage, setLogsSort } from 'store/action/logs';
import { FetchStatus, Sort } from 'types/api';

export interface Log {
    log: string;
    date: string;
}

export interface LogsState {
    fetchStatus: FetchStatus;
    error: unknown;
    data: Array<Log> | null;
    itemsCount: number;
    currentPage: number;
    sort: Sort | null;
}

const initialState: LogsState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    data: null,
    itemsCount: 0,
    currentPage: 1,
    sort: null,
};

export const logsSlice = createSlice<LogsState, SliceCaseReducers<LogsState>>({
    name: 'logs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLogs.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchLogs.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            state.data = payload.adminLogs;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchLogs.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(setLogsCurrentPage, (state, { payload }) => {
            state.currentPage = payload;
        });

        builder.addCase(setLogsSort, (state, { payload }) => {
            state.sort = payload;
        });
    },
});

export const logsReducer = logsSlice.reducer;
