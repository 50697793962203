import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { fetchPlatforms } from 'store/action/platforms';
import { useAppSelector } from 'store/store';

import { Block, Table, TableHeaderItem } from 'components';

import { PlatformsTableItem } from './PlatformsTableItem/PlatformsTableItem';

import './PlatformsTable.scss';

const CnPlatformsTable = cn('platformsTable');

export const PlatformsTable: React.FC = () => {
    const { platforms } = useAppSelector((store) => store.platforms);
    const dispatch = useDispatch();

    useEffect(() => {
        void dispatch(fetchPlatforms());
    }, [dispatch]);

    return (
        <Block>
            <Table className={CnPlatformsTable()}>
                <thead>
                    <tr>
                        <TableHeaderItem>ID</TableHeaderItem>
                        <TableHeaderItem>Имя</TableHeaderItem>
                        <TableHeaderItem>Токен</TableHeaderItem>
                        <TableHeaderItem>Действия</TableHeaderItem>
                    </tr>
                </thead>

                <tbody>
                    {platforms && platforms.map((platform) => <PlatformsTableItem key={platform.id} {...platform} />)}
                </tbody>
            </Table>
        </Block>
    );
};
