import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAccesses, fetchAuth, logout } from 'store/action/auth';
import { FetchStatus } from 'types/api';

import { getAccessToken } from 'utils/token';

export interface AuthState {
    fetchStatus: FetchStatus;
    login: { refresh: string; access: string, name: string };
    error: unknown;
    isLoggedIn: boolean;
    access: Record<string, 1 | 2>;
}

const initialState: AuthState = {
    fetchStatus: FetchStatus.INITIAL,
    login: {
        access: '',
        refresh: '',
        name: '',
    },
    error: null,
    isLoggedIn: Boolean(getAccessToken()),
    access: {}
};

export const authSlice = createSlice<AuthState, SliceCaseReducers<AuthState>>({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAuth.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAuth.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;

            window.localStorage.setItem('access', payload.tokens.access);
            window.localStorage.setItem('refresh', payload.tokens.refresh);

            state.login = {
                ...payload.tokens,
                name: payload.name
            };
            state.isLoggedIn = Boolean(getAccessToken());
        });

        builder.addCase(fetchAuth.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
            state.isLoggedIn = false;
        });

        builder.addCase(fetchAccesses.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.access = payload.adminRules;
        });

        builder.addCase(fetchAccesses.rejected, (state) => {
            state.isLoggedIn = false;
        });

        builder.addCase(logout, (state) => {
            window.localStorage.clear();
            state.isLoggedIn = false;
            state.login = {
                access: '',
                refresh: '',
                name: '',
            }

            state.access = {}
        });
    },
});

export const authReducer = authSlice.reducer;
