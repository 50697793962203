import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import App from 'App/App';
import moment from 'moment';

import 'moment/locale/ru';

import reportWebVitals from './reportWebVitals';

import './index.scss';

moment.locale('ru');

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={ru_RU}>
            <App />
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
