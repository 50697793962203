import React, { useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { fetchAdmins } from 'store/action/admins';
import { useAppDispatch, useAppSelector } from 'store/store';

import { Block, Table, TableHeaderItem } from 'components';

import { AdminsTableItem } from './AdminsTableItem/AdminsTableItem';

import './AdminsTable.scss';

const CnAdminsTable = cn('adminsTable');

export const AdminsTable: React.FC = () => {
    const { data } = useAppSelector((store) => store.admins);
    const dispatch = useAppDispatch();

    useEffect(() => {
        void dispatch(fetchAdmins());
    }, [dispatch]);

    return (
        <Block>
            <Table className={CnAdminsTable()}>
                <thead>
                    <tr>
                        <TableHeaderItem>ФИО</TableHeaderItem>
                        <TableHeaderItem>Последняя активность</TableHeaderItem>
                        <TableHeaderItem>Токен</TableHeaderItem>
                        <TableHeaderItem>Действия</TableHeaderItem>
                    </tr>
                </thead>

                <tbody>{data && data.map((admin) => <AdminsTableItem key={admin.id} {...admin} />)}</tbody>
            </Table>
        </Block>
    );
};
