import React, { useEffect, useMemo } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
    Admins,
    Auth,
    Events,
    Groups,
    Logs,
    Messages,
    Platforms,
    Receivers,
    SendedMessages,
    Templates,
    TemplatesHistory,
    Variables,
} from 'screens';
import { fetchAccesses } from 'store/action/auth';
import { store, useAppSelector } from 'store/store';

import { Header, Modal, PrivateRoute } from 'components';

import 'antd/dist/antd.min.css';
import './App.css';

const routes = [
    {
        path: '/admins',
        element: (
            <PrivateRoute>
                <Admins />
            </PrivateRoute>
        ),
        accessField: 'adminRule',
    },
    {
        path: '/admins/logs',
        element: (
            <PrivateRoute>
                <Logs />
            </PrivateRoute>
        ),
        accessField: 'adminRule',
    },
    {
        path: '/templates',
        element: (
            <PrivateRoute>
                <Templates />
            </PrivateRoute>
        ),
        accessField: 'templateRule',
    },
    {
        path: '/templates/groups',
        element: (
            <PrivateRoute>
                <Groups />
            </PrivateRoute>
        ),
        accessField: 'templateRule',
    },
    {
        path: '/platforms',
        element: (
            <PrivateRoute>
                <Platforms />
            </PrivateRoute>
        ),
        accessField: 'platformRule',
    },
    {
        path: '/events',
        element: (
            <PrivateRoute>
                <Events />
            </PrivateRoute>
        ),
        accessField: 'eventRule',
    },
    {
        path: '/receivers',
        element: (
            <PrivateRoute>
                <Receivers />
            </PrivateRoute>
        ),
        accessField: 'userRule',
    },
    {
        path: '/receivers/messages',
        element: (
            <PrivateRoute>
                <Messages />
            </PrivateRoute>
        ),
        accessField: 'userRule',
    },
    {
        path: '/variables',
        element: (
            <PrivateRoute>
                <Variables />
            </PrivateRoute>
        ),
        accessField: 'variableRule',
    },
    {
        path: '/sended',
        element: (
            <PrivateRoute>
                <SendedMessages />
            </PrivateRoute>
        ),
        accessField: 'messageRule',
    },
    {
        path: '/templates/history/',
        element: (
            <PrivateRoute>
                <TemplatesHistory />
            </PrivateRoute>
        ),
        accessField: 'templateRule',
    },
];

export const App: React.FC = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <div className="layout">
                    <Header />
                    <Modal />

                    <Navigation />
                </div>
            </BrowserRouter>
        </Provider>
    );
};

const Navigation: React.FC = () => {
    const { isLoggedIn, access } = useAppSelector((store) => store.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchAccesses());
        }
    }, [isLoggedIn, dispatch]);

    const navigatableRoute = useMemo(() => {
        if (Object.keys(access).length) {
            const item = routes.find((route) => access[route.accessField] && access[route.accessField] === 1);

            if (item) {
                return item.path;
            } else {
                return '/auth';
            }
        } else {
            return null;
        }
    }, [access]);

    const renderedRoutes = useMemo(() => {
        if (Object.keys(access).length) {
            return routes.map((route) => {
                if (access[route.accessField] && access[route.accessField] === 1) {
                    return <Route key={route.path} path={route.path} element={route.element} />;
                } else {
                    return null;
                }
            });
        } else {
            return null;
        }
    }, [access]);

    return (
        <div className="content">
            <Routes>
                {renderedRoutes}
                <Route path="/auth" element={<Auth />} />

                {!isLoggedIn && <Route path="*" element={<Navigate to="/auth" />} />}

                {navigatableRoute ? <Route path="*" element={<Navigate to={navigatableRoute} />} /> : null}
            </Routes>
        </div>
    );
};

export default App;
