import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAddAdmin, fetchAdmins, fetchChangeAccess, fetchDeleteAdmin, fetchUpdateTokenAdmin } from 'store/action/admins';
import { FetchStatus } from 'types/api';

export interface Admin {
    id: string;
    name: string;
    adminStatus: number;
    token: string;
    lastTimeUse: string;
    adminRules: {
        adminRule: number;
        id: string;
        eventRule: number;
        messageRule: number;
        platformRule: number;
        templateRule: number;
        userRule: number;
        variableRule: number;
    }
}

export interface AdminsState {
    fetchStatus: FetchStatus;
    data: Array<Admin> | null;
    error: unknown;
}

const initialState: AdminsState = {
    fetchStatus: FetchStatus.INITIAL,
    data: null,
    error: null,
};

export const adminsSlice = createSlice<AdminsState, SliceCaseReducers<AdminsState>>({
    name: 'admins',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAdmins.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAdmins.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.admins;
        });

        builder.addCase(fetchAdmins.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchAddAdmin.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAddAdmin.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.admins;
        });

        builder.addCase(fetchAddAdmin.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchDeleteAdmin.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchDeleteAdmin.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.admins;
        });

        builder.addCase(fetchDeleteAdmin.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchChangeAccess.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchChangeAccess.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.admins;
        });

        builder.addCase(fetchChangeAccess.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchUpdateTokenAdmin.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchUpdateTokenAdmin.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.data = payload.admins;
        });

        builder.addCase(fetchUpdateTokenAdmin.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });
    },
});

export const adminsReducer = adminsSlice.reducer;
