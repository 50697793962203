import { itemsPerPageCount } from 'constants/pagination';

import React, { useCallback, useEffect, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { fetchGroups } from 'store/action/groups';
import {
    fetchTemplates,
    // setTemplateGroup,
    setTemplatePage,
    setTemplateSearch,
    setTemplateSort,
} from 'store/action/templates';
import { useAppDispatch, useAppSelector } from 'store/store';
import { Sort } from 'types/api';

import { Block, Input, Pagination, Select, Table, TableHeaderItem } from 'components';

import { TemplatesTableItem } from './TemplatesTableItem/TemplatesTableItem';

import './TemplatesTable.scss';

const CnTemplatesTable = cn('templatesTable');

export const TemplatesTable: React.FC = () => {
    const { data, currentPage, itemsCount, search, sort, group } = useAppSelector((store) => store.templates);
    const dispatch = useAppDispatch();
    const pagesCount = useMemo(() => Math.ceil(itemsCount / itemsPerPageCount), [itemsCount]);
    const { data: groups } = useAppSelector((store) => store.groups);

    useEffect(() => {
        void dispatch(
            fetchTemplates({
                offset: (currentPage - 1) * itemsPerPageCount,
                search,
                sort,
                group,
            }),
        );

        void dispatch(
            fetchGroups({
                offset: 0,
            }),
        );
    }, [dispatch, currentPage, search, sort, group]);

    const pageClickHandler = (page: number) => {
        dispatch(setTemplatePage(page));
    };

    const searchChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setTemplateSearch(e.target.value));
        },
        [dispatch],
    );

    const sortChangeCallback = (value: unknown) => {
        const newValue = value as { value: Sort };
        dispatch(setTemplateSort(newValue.value));
    };

    // const groupChangeCallback = (value: unknown) => {
    //     const newValue = value as { label: string; value: string };
    //     dispatch(setTemplateGroup(newValue));
    // };

    return (
        <Block>
            <div className={CnTemplatesTable('search')}>
                <Select
                    placeholder="Сортировать по дате"
                    value={
                        {
                            asc: {
                                label: 'По возрастанию',
                                value: 'asc',
                            },
                            desc: {
                                label: 'По убыванию',
                                value: 'desc',
                            },
                        }[String(sort)]
                    }
                    onChange={sortChangeCallback}
                    options={[
                        {
                            label: 'По возрастанию',
                            value: 'asc',
                        },
                        {
                            label: 'По убыванию',
                            value: 'desc',
                        },
                    ]}
                />

                {/*<Select*/}
                {/*    placeholder="Группы"*/}
                {/*    onChange={groupChangeCallback}*/}
                {/*    value={group}*/}
                {/*    options={groups?.map(({ name, id }) => ({*/}
                {/*        label: name,*/}
                {/*        value: id,*/}
                {/*    }))}*/}
                {/*    isLoading={!groups}*/}
                {/*/>*/}

                <Input value={search} onChange={searchChangeCallback} placeholder="Имя или текст шаблона" />
            </div>
            <div className={CnTemplatesTable('scroll')}>
                <Table className={CnTemplatesTable()}>
                    <thead>
                        <tr>
                            <TableHeaderItem>Имя</TableHeaderItem>
                            <TableHeaderItem>Заголовок</TableHeaderItem>
                            <TableHeaderItem>Текст</TableHeaderItem>
                            {/*<TableHeaderItem>Группа</TableHeaderItem>*/}
                            <TableHeaderItem>Действия</TableHeaderItem>
                        </tr>
                    </thead>

                    <tbody>
                        {data && data.map((template) => <TemplatesTableItem key={template.id} {...template} {...groups}/>)}
                    </tbody>
                </Table>
            </div>

            <Pagination pagesCount={pagesCount} pageClickHandler={pageClickHandler} currentPage={currentPage} />
        </Block>
    );
};
