import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchGroups } from 'store/action/groups';
import { fetchChangeTemplate } from 'store/action/templates';

// import { useAppSelector } from 'store/store';
import { Button, Input, Title } from 'components';

import './ChangeTemplate.scss';

const CnAddTemplate = cn('addTemplate');

export const ChangeTemplate: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // const { data: groups } = useAppSelector((store) => store.groups);
    const state = location.state as {
        name: string;
        title: string;
        id: string;
        message: string;
        description: string;
        templateGroupId: string;
    };

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    // const [group, setGroup] = useState<{
    //     label: string;
    //     value: string;
    // } | null>(null);

    const [errors, setErrors] = useState<Array<'name' | 'title' | 'text' | 'group'>>([]);

    useEffect(() => {
        dispatch(fetchGroups({ offset: 0 }));
    }, [dispatch]);

    // useEffect(() => {
    //     if (groups) {
    //         const group = groups.find((group) => group.id === state.templateGroupId);
    //
    //         if (group) {
    //             setGroup({
    //                 label: group.name,
    //                 value: group.id,
    //             });
    //         }
    //     }
    // }, [groups, state.templateGroupId]);

    useEffect(() => {
        if (state.id) {
            setName(state.name);
            setTitle(state.title);
            setText(state.message);
        }
    }, [state]);

    const nameChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('name')) {
                setErrors((prev) => prev.filter((err) => err !== 'name'));
            }
            setName(e.target.value);
        },
        [errors],
    );

    const titleChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('title')) {
                setErrors((prev) => prev.filter((err) => err !== 'title'));
            }
            setTitle(e.target.value);
        },
        [errors],
    );

    const textChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (errors.includes('text')) {
                setErrors((prev) => prev.filter((err) => err !== 'text'));
            }
            setText(e.target.value);
        },
        [errors],
    );

    // const groupChangeCallback = (value: unknown) => {
    //     const newValue = value as { label: string; value: string };
    //     setGroup(newValue);
    // };

    const submitClickHandler = () => {
        const newErrors: Array<'name' | 'text' | 'title'> = [];

        if (!name) {
            newErrors.push('name');
        }

        // if (!group) {
        //     newErrors.push('group');
        // }

        if (!text) {
            newErrors.push('text');
        }

        if (!title) {
            newErrors.push('title');
        }

        if (!newErrors.length) {
            navigate(location.pathname);
            dispatch(
                fetchChangeTemplate({
                    templateId: state.id,
                    data: {
                        name,
                        title: title,
                        message: text,
                        // templateGroupId: group?.value,
                    },
                }),
            );
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className={CnAddTemplate()}>
            <div className={CnAddTemplate('header')}>
                <Title size="xs">Изменить шаблон</Title>
                {/* <Subtitle size="m">{state?.name}</Subtitle> */}
            </div>

            <div className={CnAddTemplate('form')}>
                {/*<Select*/}
                {/*    placeholder="Группы"*/}
                {/*    onChange={groupChangeCallback}*/}
                {/*    value={group}*/}
                {/*    label="Группа"*/}
                {/*    options={groups?.map(({ name, id }) => ({*/}
                {/*        label: name,*/}
                {/*        value: id,*/}
                {/*    }))}*/}
                {/*    isLoading={!groups}*/}
                {/*/>*/}

                <Input
                    isInvalid={errors.includes('name')}
                    value={name}
                    onChange={nameChangeCallback}
                    label="Название"
                    invalidMessage="Обязательное поле"
                    placeholder=""
                />

                <Input
                    isInvalid={errors.includes('title')}
                    value={title}
                    onChange={titleChangeCallback}
                    label="Заголовок"
                    placeholder=""
                    invalidMessage="Обязательное поле"
                />

                <Input
                    isInvalid={errors.includes('text')}
                    value={text}
                    onChange={textChangeCallback}
                    as="textarea"
                    label="Текст"
                    invalidMessage="Обязательное поле"
                    placeholder=""
                    rows={5}
                />
            </div>

            <div className={CnAddTemplate('actions')}>
                <Button onClick={submitClickHandler} view="primary" size="m">
                    Изменить
                </Button>

                {/* <Button onClick={cancelClickHandler} view="secondary" size="m">
                    Отмена
                </Button> */}
            </div>
        </div>
    );
};
