import React from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import moment from 'moment';
import { fetchRestoreTemplate } from 'store/action/templatesHistory';
import { Template } from 'store/reducer/templates';

import { TableItem } from 'components';

import './TemplatesHistoryTableItem.scss';

const CnTemplatesTableItem = cn('templatesTableItem');

interface ITemplatesTableItemProps extends Template {
    offset: number;
    currentTemplateId: string;
}

export const TemplatesHistoryTableItem: React.FC<ITemplatesTableItemProps> = ({
    id,
    message,
    title,
    createdAt,
    offset,
    currentTemplateId,
}) => {
    const dispatch = useDispatch();

    const restoreTemplateHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch(fetchRestoreTemplate({ offset, templateHistoryId: id, id: currentTemplateId }));
    };

    return (
        <tr>
            <TableItem>{moment(createdAt).format('DD MMMM YYYY HH:mm')}</TableItem>
            <TableItem>{title}</TableItem>
            <TableItem>{message}</TableItem>
            <TableItem>
                <div className={CnTemplatesTableItem('actions')}>
                    <div onClick={restoreTemplateHandler} className={CnTemplatesTableItem('action')}>
                        <svg
                            className={CnTemplatesTableItem('icon')}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.917 2.75C7.36116 2.75 3.66699 6.44417 3.66699 11H0.916992L4.48283 14.5658L4.54699 14.6942L8.25033 11H5.50033C5.50033 7.4525 8.36949 4.58333 11.917 4.58333C15.4645 4.58333 18.3337 7.4525 18.3337 11C18.3337 14.5475 15.4645 17.4167 11.917 17.4167C10.1478 17.4167 8.54366 16.6925 7.38866 15.5283L6.08699 16.83C7.58116 18.3242 9.63449 19.25 11.917 19.25C16.4728 19.25 20.167 15.5558 20.167 11C20.167 6.44417 16.4728 2.75 11.917 2.75ZM11.0003 7.33333V11.9167L14.9237 14.245L15.5837 13.1358L12.3753 11.2292V7.33333H11.0003Z"
                                fill="#201D1D"
                            />
                        </svg>
                        Восстановить
                    </div>
                </div>
            </TableItem>
        </tr>
    );
};
