import React from 'react';

import {
    AddAdmin,
    AddPlatform,
    AddTemplate,
    AddVariable,
    ChangeAccess,
    ChangeTemplate,
    ChangeVariable,
    DeleteAdmin,
} from 'components';
import { AddEvent } from 'components/AddEvent/AddEvent';
import { AddGroup } from 'components/AddGroup/AddGroup';
import { ChangeEvent } from 'components/ChangeEvent/ChangeEvent';
import { ChangeGroup } from 'components/ChangeGroup/ChangeGroup';
import { DeleteEvent } from 'components/DeleteEvent/DeleteEvent';
import { DeleteGroup } from 'components/DeleteGroup/DeleteGroup';
import { DeletePlatform } from 'components/DeletePlatform/DeletePlatform';
import { DeleteTemplate } from 'components/DeleteTemplate/DeleteTemplate';
import { DeleteVariable } from 'components/DeleteVariable/DeleteVariable';
import { Unsubscribe } from 'components/Unsubscribe/Unsubscribe';

export const modalTypes: Record<string, JSX.Element> = {
    'add-admin': <AddAdmin />,
    'delete-admin': <DeleteAdmin />,
    'add-template': <AddTemplate />,
    'change-template': <ChangeTemplate />,
    'add-platform': <AddPlatform />,
    'add-variable': <AddVariable />,
    'change-variable': <ChangeVariable />,
    'change-access': <ChangeAccess />,
    unsubscribe: <Unsubscribe />,
    'delete-platform': <DeletePlatform />,
    'delete-template': <DeleteTemplate />,
    'delete-variable': <DeleteVariable />,
    'add-group': <AddGroup />,
    'change-group': <ChangeGroup />,
    'delete-group': <DeleteGroup />,
    'add-event': <AddEvent />,
    'change-event': <ChangeEvent />,
    'delete-event': <DeleteEvent />,
};
