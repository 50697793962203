import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Delete, Pen } from 'assets';
import { setTemplatesHistoryTemplate } from 'store/action/templatesHistory';
// import {Group} from "store/reducer/groups";
import { Template } from 'store/reducer/templates';

import { TableItem } from 'components';

import './TemplatesTableItem.scss';

const CnTemplatesTableItem = cn('templatesTableItem');

type ITemplatesTableItemProps = Template;

export const TemplatesTableItem: React.FC<ITemplatesTableItemProps> = ({
    id,
    message,
    title,
    description,
    name,
    createdAt,
    templateGroupId,
}) => {
// },groups:Group[]) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const group = groups!=null?groups?.find(obj => obj.id === templateGroupId):null
    // const group = Array.isArray(groups)?groups?.find((obj) => obj.id == templateGroupId):null

    const deleteTemplateHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        navigate('?modal=delete-template', {
            state: {
                id,
                message,
                title,
                description,
                name,
                createdAt,
                templateGroupId,
            },
        });
    };

    const changeTemplateHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        navigate('?modal=change-template', {
            state: {
                id,
                message,
                title,
                description,
                name,
                createdAt,
                templateGroupId,
            },
        });
    };

    const templateClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        dispatch(
            setTemplatesHistoryTemplate({
                id,
                message,
                title,
                description,
                name,
                createdAt,
                templateGroupId,
            }),
        );

        navigate('/templates/history');
    };

    return (
        <tr onClick={templateClickHandler}>
            <TableItem>{name}</TableItem>
            <TableItem>{title}</TableItem>
            <TableItem>{message}</TableItem>
            {/*<TableItem>{templateGroupId=='00000000-0000-0000-0000-000000000000'?'отсутствует':templateGroupId}</TableItem>*/}
            <TableItem>
                <div className={CnTemplatesTableItem('actions')}>
                    <div onClick={changeTemplateHandler} className={CnTemplatesTableItem('action')}>
                        <Pen className={CnTemplatesTableItem('icon')} />
                        Изменить
                    </div>

                    <div className={CnTemplatesTableItem('action')} onClick={deleteTemplateHandler}>
                        <Delete className={CnTemplatesTableItem('icon')} />
                        Удалить
                    </div>
                </div>
            </TableItem>
        </tr>
    );
};
