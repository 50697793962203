import { itemsPerPageCount } from "constants/pagination";

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAddGroupsRequest, fetchChangeGroupsRequest, fetchDeleteGroupsRequest, fetchGroupsRequest } from "api/groups";


export const fetchGroups = createAsyncThunk("@groups/fetch", async (payload: { offset: number, limit?: number, search?: string }) => {
    return await fetchGroupsRequest({ limit: 150, ...payload });
})

export const fetchAddGroups = createAsyncThunk("@groups/add", async (payload: string) => {
    await fetchAddGroupsRequest(payload);

    return await fetchGroupsRequest({ offset: 0, limit: itemsPerPageCount });
})

export const fetchChangeGroups = createAsyncThunk("@groups/change", async (payload: { name: string, templateGroupId: string }) => {
    await fetchChangeGroupsRequest(payload);

    return await fetchGroupsRequest({ offset: 0, limit: itemsPerPageCount });
})

export const fetchDeleteGroups = createAsyncThunk("@groups/delete", async (payload: { templateGroupId: string }) => {
    await fetchDeleteGroupsRequest(payload);

    return await fetchGroupsRequest({ offset: 0, limit: itemsPerPageCount });
})

export const setGroupsCurrentPage = createAction<number>("@groups/setCurrentPage");

export const setGroupsSearch = createAction<string>("@groups/setSearch");

