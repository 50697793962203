import React from 'react';
import { cn } from '@bem-react/classname';
import moment from 'moment';
import { SendedMessage } from 'store/reducer/sendedMessages';

import { TableItem, Tag } from 'components';

import './SendedMessagesTableItem.scss';

const CnSendedMessagesTableItem = cn('sendedMessagesTableItem');

export const SendedMessagesTableItem: React.FC<SendedMessage> = ({ date, externalUserId, title, message, tags }) => {
    return (
        <tr className="sendedMessagesTableItem">
            <TableItem>{moment(date).format('DD MMMM YYYY HH:mm')}</TableItem>
            <TableItem>{externalUserId}</TableItem>
            <TableItem>
                <div className={CnSendedMessagesTableItem('message')}>
                    <div className={CnSendedMessagesTableItem('title')}>{title}</div>
                    <div className={CnSendedMessagesTableItem('text')}>{message}</div>
                </div>
            </TableItem>
            <TableItem>
                <div className={CnSendedMessagesTableItem('tags')}>
                    {tags
                        ? tags.map((tag) => {
                              return <Tag key={tag.id} text={tag.name}></Tag>;
                          })
                        : 'Отсутствуют'}
                </div>
            </TableItem>
        </tr>
    );
};
