import React from 'react';
import { cn } from '@bem-react/classname';

import { ReceiversTable, Title } from 'components';

import './Receivers.scss';

const CnReceivers = cn('receivers');

export const Receivers: React.FC = () => {
    return (
        <div className={CnReceivers()}>
            <div className={CnReceivers('header')}>
                <Title size="m">Получатели</Title>
            </div>

            <div className={CnReceivers('content')}>
                <ReceiversTable />
            </div>
        </div>
    );
};
