import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAccessesRequest, fetchAuthRequest } from 'api/auth';

export const fetchAuth = createAsyncThunk('@auth/fetch', async (token: string) => {
    return await fetchAuthRequest(token);
});

export const fetchAccesses = createAsyncThunk('@auth/accesses', async () => {
    return await fetchAccessesRequest();
});


export const logout = createAction("@auth/logout");