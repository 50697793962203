import React, { useCallback, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Eye } from 'assets';

import './Input.scss';

const CnInput = cn('input');

interface IInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    view?: 'default';
    cnSize?: 'default';
    isInvalid?: boolean;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    as?: 'input' | 'textarea';
    cols?: number;
    rows?: number;
    invalidMessage?: string;
}

export const Input: React.FC<IInputProps> = ({
    view = 'default',
    cnSize = 'default',
    isInvalid = false,
    label,
    disabled,
    type,
    as = 'input',
    invalidMessage = 'Неправильное значение',
    ...other
}) => {
    const [inputType, setInputType] = useState(type);

    const inputTypeChangeCallback = useCallback(() => {
        if (inputType === type) {
            setInputType('text');
        } else {
            setInputType(type);
        }
    }, [inputType, type]);

    return (
        <div className={CnInput('wrapper')}>
            {label ? <div className={CnInput('label', { disabled })}>{label}</div> : null}

            {React.createElement(as, {
                className: CnInput({ view, size: cnSize, invalid: isInvalid }),
                type: inputType,
                disabled,
                ...other,
            })}

            {type === 'password' ? (
                <div onClick={inputTypeChangeCallback} className={CnInput('eye')}>
                    <Eye />
                </div>
            ) : null}

            {isInvalid ? <div className={CnInput('error')}>{invalidMessage}</div> : null}
        </div>
    );
};
