import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchReceiversBlockRequest, fetchReceiversRequest } from 'api/receivers';
import { UserBannStatus } from 'types/rules';

export const fetchReceivers = createAsyncThunk('@receivers/fetch', async (payload: { offset: number, search: string, sort: 'asc' | 'desc' | null }) => {
    return await fetchReceiversRequest(payload);
});

export const fetchBlockReceivers = createAsyncThunk('@receivers/fetchBlock', async (payload: { userId: string, userStatus: UserBannStatus }) => {
    await fetchReceiversBlockRequest(payload);

    return await fetchReceiversRequest({
        offset: 0,
        search: '',
        sort: null,
    });
});


export const setReceiversPage = createAction<number>("@receivers/setPage");

export const setReceiversSearch = createAction<string>("@receivers/setSearch");

export const setReceiversSort = createAction<'asc' | 'desc'>("@receivers/setSort");
