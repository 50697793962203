import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAddPlatform, fetchDeletePlatform, fetchPlatforms } from 'store/action/platforms';
import { FetchStatus } from 'types/api';

export interface Platform {
    id: string;
    name: string;
    token: string;
}

export interface PlatformsState {
    fetchStatus: FetchStatus;
    error: unknown;
    platforms: Array<Platform> | null;
}

const initialState: PlatformsState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    platforms: null,
};

export const platformsSlice = createSlice<PlatformsState, SliceCaseReducers<PlatformsState>>({
    name: 'platforms',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPlatforms.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchPlatforms.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.platforms = payload.platforms;
        });

        builder.addCase(fetchPlatforms.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchAddPlatform.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchAddPlatform.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.platforms = payload.platforms;
        });

        builder.addCase(fetchAddPlatform.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchDeletePlatform.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.platforms = payload.platforms;
        });
    },
});

export const platformsReducer = platformsSlice.reducer;
