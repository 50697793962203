import React from 'react';
import { cn } from '@bem-react/classname';

import './Tag.scss';

const CnTag = cn('tag');

interface ITagProps {
    text: string;
}

export const Tag: React.FC<ITagProps> = ({ text }) => {
    return <div className={CnTag()}>{text}</div>;
};
