import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { fetchDeleteAdmin } from 'store/action/admins';

import { Button, Subtitle, Title } from 'components';

import './DeleteAdmin.scss';

const CnDeleteAdmin = cn('deleteAdmin');

export const DeleteAdmin: React.FC = () => {
    const location = useLocation();
    const state = location.state as { name: string; token: string; id: number };

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const deleteClickHandler = () => {
        navigate(location.pathname);

        dispatch(fetchDeleteAdmin(state.id));
    };

    const cancelClickHandler = () => {
        navigate(location.pathname, { state });
    };

    return (
        <div className={CnDeleteAdmin()}>
            <div className={CnDeleteAdmin('header')}>
                <Title size="xs">Удалить администратора</Title>
                <Subtitle size="m">{state?.name}</Subtitle>
            </div>

            <div className={CnDeleteAdmin('actions')}>
                <Button onClick={deleteClickHandler} view="primary" size="m">
                    Удалить
                </Button>

                <Button onClick={cancelClickHandler} view="secondary" size="m">
                    Отмена
                </Button>
            </div>
        </div>
    );
};
