import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchBlockReceivers, fetchReceivers, setReceiversPage, setReceiversSearch, setReceiversSort } from 'store/action/receivers';
import { FetchStatus } from 'types/api';
import { UserBannStatus } from 'types/rules';

export interface Receiver {
    id: string;
    name: string;
    lastTimeMessage: string;
    externalUserId: string;
    userStatus: UserBannStatus;
    countUnsubscribed: number;
}

export interface ReceiversState {
    fetchStatus: FetchStatus;
    error: unknown;
    receivers: Array<Receiver> | null;
    currentPage: number;
    itemsCount: number;
    search: string;
    sort: 'asc' | 'desc' | null;
}

const initialState: ReceiversState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    receivers: null,
    currentPage: 1,
    itemsCount: 0, 
    search: "",
    sort: null,
};

export const receiversSlice = createSlice<ReceiversState, SliceCaseReducers<ReceiversState>>({
    name: 'receivers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchReceivers.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchReceivers.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.receivers = payload.users;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchReceivers.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchBlockReceivers.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(fetchBlockReceivers.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.error = null;
            state.receivers = payload.users;
            state.itemsCount = payload.countItems;
        });

        builder.addCase(fetchBlockReceivers.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(setReceiversPage, (state, { payload }) => {
            state.currentPage = payload;
        })

        builder.addCase(setReceiversSearch, (state, { payload }) => {
            state.search = payload;
            state.currentPage = 1;
        })

        builder.addCase(setReceiversSort, (state, { payload }) => {
            state.sort = payload;
            state.currentPage = 1;
        })
    },
});

export const receiversReducer = receiversSlice.reducer;
