import React from 'react';
import { cn } from '@bem-react/classname';

import './Subtitle.scss';

const CnSubtitle = cn('subtitle');

interface ISubtitleProps {
    size?: 's' | 'm' | 'l';
}

export const Subtitle: React.FC<ISubtitleProps> = ({ children, size = 'm' }) => {
    return <div className={CnSubtitle({ size })}>{children}</div>;
};
