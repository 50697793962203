import { host } from 'constants/api';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { Copy, Delete, EyeEmpty, LockOpen, Refresh } from 'assets';
import axios, { AxiosResponse } from 'axios';
import { fetchUpdateTokenAdmin } from 'store/action/admins';
import { Admin } from 'store/reducer/admins';

import './LogsInfo.scss';

const CnLogsInfo = cn('logsInfo');

export const LogsInfo: React.FC<{
    id: string;
    name: string;
    token: string;
    rulesId: string;
    rules: Record<string, 1 | 2>;
}> = (props) => {
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const { token, name, id, rules } = props;
    const [admin, setAdmin] = useState<Admin | null>(null);
    const {
        adminRule,
        id: rulesId,
        eventRule,
        messageRule,
        platformRule,
        templateRule,
        userRule,
        variableRule,
    } = rules;

    useEffect(() => {
        fetchAdmin(id);
    }, [id]);

    const [isTokenShow, setIsTokenShow] = useState(false);

    const isTokenShowChangeCallback = useCallback(() => {
        setIsTokenShow(!isTokenShow);
    }, [isTokenShow]);

    const fetchAdmin = (id: string) => {
        void axios.get<never, AxiosResponse<{ admin: Admin }>>(`${host}/admin/admins?adminId=${id}`).then((res) => {
            setAdmin(res.data.admin);
        });
    };

    const deleteAdminModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        navigate(`?modal=delete-admin`, {
            state: {
                id,
                name,
                token,
                rulesId,
                rules: {
                    adminRule,
                    eventRule,
                    messageRule,
                    platformRule,
                    templateRule,
                    userRule,
                    variableRule,
                },
            },
        });
    };

    const changeAccessModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        navigate(`?modal=change-access`, {
            state: {
                id,
                name,
                token,
                rulesId,
                rules: {
                    adminRule,
                    eventRule,
                    messageRule,
                    platformRule,
                    templateRule,
                    userRule,
                    variableRule,
                },
            },
        });
    };

    const dispatch = useDispatch();

    const updateAdminTokenHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();

        dispatch(fetchUpdateTokenAdmin(id));

        setTimeout(() => {
            fetchAdmin(id);
        }, 1000);
    };

    const copyClickHandler = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.stopPropagation();

        void navigator.clipboard.writeText(token).then(() => console.log('success'));
    };

    return (
        <div className={CnLogsInfo()}>
            <div className={CnLogsInfo('row')}>
                <div className={CnLogsInfo('text')}>Администратор</div>

                <svg
                    className={CnLogsInfo('dot')}
                    width="4"
                    height="4"
                    viewBox="0 0 4 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="2" cy="2" r="2" fill="#C4C4C4" />
                </svg>

                <div className={CnLogsInfo('text')}>Токен:</div>

                <div className={CnLogsInfo('token')}>
                    {isTokenShow ? (admin ? admin.token : token) : '••••••••••••••••••••••••••••••'}

                    <EyeEmpty onClick={isTokenShowChangeCallback} className={CnLogsInfo('icon')} />
                    <Refresh onClick={updateAdminTokenHandler} className={CnLogsInfo('icon')} />
                    <Copy onClick={copyClickHandler} className={CnLogsInfo('icon')} />
                </div>
            </div>

            <div className={CnLogsInfo('actions')}>
                <div onClick={changeAccessModal} className={CnLogsInfo('action')}>
                    <LockOpen className={CnLogsInfo('icon')} />
                    Доступы
                </div>

                <div className={CnLogsInfo('action')} onClick={deleteAdminModal}>
                    <Delete className={CnLogsInfo('icon')} />
                    Удалить
                </div>
            </div>
        </div>
    );
};
