import React from 'react';
import RSelect from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { cn } from '@bem-react/classname';

import './Select.scss';

const CnSelect = cn('select');

interface ISelectProps extends StateManagerProps {
    label?: string;
}

export const Select: React.FC<ISelectProps> = ({ label, ...other }) => {
    return (
        <div className={CnSelect('wrapper')}>
            {label ? <div className={CnSelect('label')}>{label}</div> : null}
            <RSelect className={CnSelect()} classNamePrefix={CnSelect()} {...other} />
        </div>
    );
};
